let { rh } = window;
let { _ } = rh;
let { $ } = rh;

class TableNestedData extends rh.Widget {

  constructor(opts) {
    super(opts);
    this.rowColMatrix = this.extractRowColumnMatrix(this.node);
  }
 
  extractRowColumnMatrix(node) {
    let rowColMatrix = [];
    let rowElements = [];
    this.traverseNode(node, function(node) {
      if ('TD' === $.nodeName(node)) {
        if (this.hasOnlyTable(node)) {
          let childMatrix = this.extractRowColumnMatrix(node.children[0]);
          if (childMatrix.length !== 0) { rowElements.push({child: childMatrix}); }
        } else {
          rowElements.push({html: $.innerHTML(node)});
        }
        return false;
      } else if ('TR' === $.nodeName(node)) {
        if (rowElements.length !== 0) { rowColMatrix.push(rowElements); }
        rowElements = [];
      }
      return true;
    });
    if (rowElements.length !== 0) { rowColMatrix.push(rowElements); }
    return rowColMatrix;
  }

  hasOnlyTable(node) {
    return ((node.children != null ? node.children.length : undefined) === 1) && ('TABLE' === $.nodeName(node.children[0]));
  }
}

//rh.registerDataAttr 'tabler', TableNestedData
window.rh.widgets.TableNestedData = TableNestedData;