let { rh } = window;

class Guard {
  
  constructor() {
    this.guard = this.guard.bind(this);
  }

  guard(fn, guardName) {
    if (this.guardedNames == null) { this.guardedNames = []; }
    if (this.guardedNames.indexOf(guardName) === -1) {
      this.guardedNames.push(guardName);
      fn.call(this);
      return this.guardedNames.splice(this.guardedNames.indexOf(guardName), 1);
    }
  }
}

rh.Guard = Guard;
rh.guard = (new Guard()).guard;
  