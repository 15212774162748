let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

class Iframe extends rh.Guard {

  toString() { return 'Iframe'; }

  constructor() {
    super();
    this.unsubscribe = this.unsubscribe.bind(this);
    this.linkedSubs = {};
    if (_.isIframe()) {
      rh.model.subscribe(consts('EVT_BEFORE_UNLOAD'), this.unsubscribe);
      rh.model.subscribe(consts('EVT_UNLOAD'), this.unsubscribe);
    }
  }

  unsubscribe() {
    if (this.parent) {
      let msg = {id: this.id};
      this.parent.postMessage({ rhmodel_unsubscribe: msg }, _.getOrigin(this.parent));
      return this.parent = undefined;
    }
  }

  init() {
    if (this.id == null) { this.id = _.uniqueId(); }
    this.parent = window.parent;
    if (_.isIframe()) {
      let input = rh.model.get('_sharedkeys.input');
      if (input) {
        let inputKeys = _.map(input, function(item) {
          if (_.isString(item)) { return {key: item}; } else { return item; }
        });
        let msg = {input: inputKeys, id: this.id};
        this.parent.postMessage({ rhmodel_subscribe: msg }, _.getOrigin(this.parent));
      }
      let outputKeys = rh.model.get('_sharedkeys.output');
      if (outputKeys) { return this.linkModel(this.parent, this.id, outputKeys); }
    }
  }

  clean(id) {
    let subs = this.linkedSubs[id];
    if (subs) {
      for (let unsub of Array.from(subs)) { unsub(); }
      return delete this.linkedSubs[id];
    }
  }

  linkModel(source, id, keys) {
    if (keys == null) { keys = []; }
    let subs = [];
    let callback = (value, key) => {
      return this.guard(function() {
        let msg = {}; msg[key] = value;
        return source.postMessage({ rhmodel_publish: msg }, _.getOrigin(source));
      }
      , id);
    };
    for (let key of Array.from(keys)) {
      key = key.trim();
      subs.push(rh.model.subscribe(key, callback));
    }
    this.clean(id);
    return this.linkedSubs[id] = subs;
  }

  publish(key, value, opts) {
    if (opts == null) { opts = {}; }
    return this.guard(() => rh.model.publish(key, value, opts));
  }

  guard(fn, guardName) {
    if (guardName == null) { guardName = this.id; }
    return super.guard(fn, guardName);
  }
}

rh.iframe = new Iframe();
