let rh = require('../../lib/rh')
let _ = rh._
_.isAND = ( a_strOp, enableOperatorSearch ) =>{
  return (enableOperatorSearch &&
            (a_strOp === "and" || a_strOp === "&"|| a_strOp === "AND"))||
            a_strOp === "\u00ACand\u00AC" ;
}

_.isOR = ( a_strOp, enableOperatorSearch ) =>{
  return ( enableOperatorSearch &&
				(a_strOp ==="or" || a_strOp === "|" || a_strOp === "OR"));
}

_.isNOT = ( a_strOp, enableOperatorSearch) =>{
  return  enableOperatorSearch &&
						(a_strOp === "not" || a_strOp === "~" || a_strOp === "NOT")
}

_.isOperator = ( strOp, enableOperatorSearch ) =>{
  if ( strOp === "\u00ACand\u00AC"||
        (enableOperatorSearch &&
				(strOp === "and" || strOp === "or" || strOp === "not" ))){
    return true
  }
  return false      
}
