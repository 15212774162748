let { rh } = window;
let { _ } = rh;
let { consts } = rh;
let { Widget } = rh;
import $ from '../common/query'

class List extends Widget {
  static initClass() {

    this.prototype.dataIAttrs = ['child'].concat(Widget.prototype.dataIAttrs);
    this.prototype.dataIAttrMethods = (() => Widget.prototype.mapDataAttrMethods(List.prototype.dataIAttrs))();

    this.prototype.supportedArgs = ['node', 'model', 'key', 'user_vars', 'filter',
     'spliton', 'path', 'tplNode', 'tplChildNodes'];
  }

  constructor(opts) {
    super(opts);
    this.reRender = this.reRender.bind(this);

    if (this.key == null) { this.key = `_${this}`; }
    if (this.path == null) { this.path = []; }
    if (this.children == null) { this.children = []; }
    if (this.user_vars == null) { this.user_vars = {}; }
    this.useTemplate = true;
    this.renderedIndex = 0;
    this.renderedCount = 0;
  }

  init(parent) {
    if (this.initDone) { return; }
    super.init(parent);
    this.subscribeOnly(this.key, this.reRender, { partial: false });
    this.subscribeExpr(this.keyexpr, function(result) { if (result == null) { result = []; } return this.publish(this.key, result, {sync: true}); });
    this.subscribe(consts('EVT_RESIZE'),this.checkIfMoreItemsrequired.bind(this, true))
    return this.subscribeOnly(this.opts.loadmore, () => this.renderChunck(true));
  }

  parseOpts(opts) {
    super.parseOpts(opts);
    if (this.key) {
      if (_.isValidModelConstKey(this.key)) { this.key = consts(this.key); }
      if (!_.isValidModelKey(this.key)) {
        this.keyexpr = this.key;
        return this.key = null;
      }
    }
  }

  parsePipedArg() {
    let args = this.opts.pipedArgs;
    if (args != null ? args.shift : undefined) {
      let arg;
      if (arg = args.shift()) { this.filter = arg; }
      if (arg = args.shift()) { this.spliton = arg; }
    }

    if (_.isString(this.filter)) { this.filter = this.listItemExpr(this.filter); }
    if (_.isString(this.spliton)) { return this.spliton = this.listItemExpr(this.spliton); }
  }

  notifyLoading(value) { if (this.opts.loading) { return this.publish(this.opts.loading, value); } }

  listItemExpr(expr) { return this._evalFunction('item, index', expr); }

  isWidgetNode(node) { return super.isWidgetNode(...arguments) || $.dataset(node, 'child'); }

  reRender(render) {
    this.data = null;
    this.renderedIndex = 0;
    this.renderedCount = 0;
    return super.reRender(render);
  }

  preRender() {
    let node;
    let oldNode = this.node;
    if (this.tplChildNodes == null) {
      this.tplChildNodes = ((() => {
        let result = [];
        for (node of Array.from(this.tplNode.childNodes)) {           result.push(node);
        }
        return result;
      })());
    }

    this.node = this.tplNode.cloneNode(false);
    return oldNode;
  }

  alterNodeContent() {
    if (this.data == null) { this.data = this.get(this.key) || []; }
    return (this.renderChunck)();
  }

  renderChunck(resolve = false) {
    let i;
    let end;
    this.notifyLoading(false);
    for (i = this.renderedIndex, end = this.data.length - 1; i <= end; i++) {
      let item = this.data[i];
      if (this.filter && !this.filter(item, i)) { continue; }
      if (this.spliton && (i !== this.renderedIndex) && this.spliton(item, this.renderedCount)) {
        this.notifyLoading(true);
        break;
      } else {
        this.renderOneItem(item, i, resolve);
      }
    }
    this.renderedIndex = i;
    if (this.renderedCount === 0) { this.hide(); } else if (!this.isVisible()) { this.show(); }
    if (this.opts.loaded && (i === this.data.length)) { return this.publish(this.opts.loaded, true); }
    this.checkIfMoreItemsrequired(resolve)
  }

  checkIfMoreItemsrequired(resolve) { 
    if (this.renderedCount > 0) {
      _.defer(() => {
        if (this.shouldLoadMoreItems()) {
          this.renderOneMoreItem(resolve)
        }
      })
    }
  }

  renderOneMoreItem(resolve = false) {
    let i = this.renderedIndex = this.renderedIndex + 1
    let item = this.data[i]
    if (item)
    { 
      this.renderOneItem(item, i, resolve)
      this.checkIfMoreItemsrequired(resolve)      
    }
  }

  shouldLoadMoreItems() {
    let parentNode = this.node.offsetParent
    if (parentNode && $.getAttribute(parentNode,'data-scroll')) {
      if (parentNode.scrollHeight <= parentNode.clientHeight) { 
        return this.renderedCount < this.data.length
      }
    }
    return false
  }

  renderOneItem(item, index, resolve) {
    this.renderedIndex = index;
    let generateindex = this.opts.generateindex || rh._debug;
    for (let node of Array.from(this.tplChildNodes)) {
      var newNode;
      if ((newNode = this.resolve_rif(node, item, index))) {
        if (incremented == null) {
          this.renderedCount++;
          var incremented = true;
        }
        if (generateindex) { $.dataset(newNode, 'listindex', this.renderedCount - 1); }
        if (newNode.hasChildNodes()) { this.renderChildList(newNode, item, index); }
        this.node.appendChild(newNode);
        this.resolveItemIndex(newNode, item, index);
        if($.isElementNode(newNode) && resolve) {
          this.resolveDataAttrs(newNode);
          _.loadDataHandlers(newNode, this);
        }
      }
    }
  }

  convertToListContainer(node) {}

  _pathId(index) {
    let id = '_';
    id += this.path.join('_');
    if (index != null) {
      if (this.path.length > 0) { id += '_'; }
      id += index;
    }
    return id;
  }

  _pathKey(subpath) {
    if (subpath == null) { subpath = ''; }
    subpath = subpath.toString();
    let path = this.path.join('.');
    if ((subpath.length > 0) && (path.length > 0)) {
      return `.${path}.${subpath}`;
    } else if (subpath.length > 0) {
      return `.${subpath}`;
    } else {
      return `.${path}`;
    }
  }

  /*
   * @path: unique path for list
   * @ppath: unique path of parent
   */
  resolveRepeatVar(expr, item, index, cache, node) {
    return cache[expr] = cache[expr] || (() => { switch (expr) {
      case '@itemkey': return `${this.key}.${index}`;
      case '@key': return this.key;
      case '@id': return this._pathId(index);
      case '@pid': return this._pathId();
      case '@path': return this._pathKey(index);
      case '@ppath': return this._pathKey();
      case '@level': return this.path.length;
      default: return super.resolveRepeatVar(expr, item, index, cache, node);
    } })();
  }

  data_child(node, rawExpr, item, index, attrsInfo) {
    if (!_.isValidModelKey(rawExpr)) {
      $.dataset(node, 'child', this.subscribeIDataExpr(node, rawExpr, item, index));
    }
    return false;
  }

  /*
   * it can be key or expression
   * data-child="value"
   * data-child="@.p.value"
   */
  renderChildList(node, item, index) {
    return $.eachDataNode(node, 'child', function(childNode, value) {
      this.convertToListContainer(node);
      this.resolveItemIndex(childNode, item, index);

      value = $.dataset(childNode, 'child'); //get updated value
      if ((value === 'undefined') || (value === '')) {
        return childNode.parentNode.removeChild(childNode);
      } else {
        let args = value.split('|');
        let filter = args[1];
        let childkey = args[0];

        let childList = new List({
          node: childNode,
          model: this.model,
          key: childkey,
          user_vars: this.user_vars,
          path: this.path.concat([this.renderedCount - 1]),
          filter,
          tplNode: childNode.cloneNode(false),
          tplChildNodes: this.tplChildNodes
        });

        childList.init(this);
        return this.children.push(childList);
      }
    }
    , this);
  }
}
List.initClass();

window.rh.widgets.List = List;
