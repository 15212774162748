let { rh } = window;
let { _ } = rh;
let { consts } = rh;
let { model } = rh;

class Responsive {

  toString() { return 'Responsive'; }

  constructor() {
    this.subs = [];
    model.subscribe(consts('EVT_ORIENTATION_CHANGE'), () => {
      return _.each(this.subs, sub => sub.eventHandler(sub.mql));
    });

    if (rh._debug && !this.isSupported()) {
      rh._d('error', 'Browser Issue', 'matchMedia is not supported.');
    }
  }

  isSupported() { return (window.matchMedia != null ); }

  attach(media_query, onFn, offFn) {
    if (this.isSupported) {
      let mql = window.matchMedia(media_query);
      let eventHandler = function(mql) { if (mql.matches) { return onFn(); } else { return offFn(); } };
      eventHandler(mql);
      mql.addListener(eventHandler);
      return this.subs.push({mql, on: onFn, off: offFn, eventHandler});
    }
  }

  detach(media_query, onFn, offFn) {
    for (let index = 0; index < this.subs.length; index++) {
      let sub = this.subs[index];
      if ((sub.mql.media === media_query) && (sub.on === onFn) && (sub.off === offFn)) {
        sub.mql.removeListener(sub.eventHandler);
        this.subs.splice(index);
        break;
      }
    }
  }
}

rh.responsive = new Responsive();