let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

class Resize {
  constructor(widget, node, rawExpr) {
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.widget = widget;
    this.node = node;
    let {callback, opts} = this.widget.resolveRawExprWithValue(rawExpr);
    this.opts = opts;
    this.callback = function() { return callback.call(this.widget); };

    if (opts.maxx == null) { opts.maxx = 1; }
    if (opts.minx == null) { opts.minx = 0; }
    if (opts.maxy == null) { opts.maxy = 1; }
    if (opts.miny == null) { opts.miny = 0; }
    this.resize = false;
    
    _.initMouseMove();
    _.addEventListener(this.node, 'mousedown', this.handleMouseDown);
    this.widget.subscribe(consts('EVT_MOUSEMOVE'), this.handleMouseMove);
  }

  handleMouseDown(evt) {
    if (evt.which !== 1) { return; }
    this.resize = (evt.target === this.node) && !evt.defaultPrevented;
    if (this.resize) {
      let result = (this.callback)();
      return this.resize = (result !== false) && (result !== null);
    }
  }

  handleMouseMove(obj) {
    if (obj.defaultPrevented) { this.resize = false; }
    if (!this.resize) { return; }

    obj.defaultPrevented = true;
    if (obj.which === 1) {
      return this.publish(obj);
    } else {
      return this.resize = false;
    }
  }

  getBaseWidth() { return this.opts.basex || document.body.offsetWidth; }
    
  getBaseHeight() { return this.opts.basey || document.body.offsetHeight; }
  
  publish(obj) {
    let base = this.getBaseWidth();
    let rtl = 'rtl' === this.widget.get(consts('KEY_DIR'));
    let newValue = rtl ? base - obj.x : obj.x;
    if (!this.publishPos(base, this.opts.minx, this.opts.maxx, this.opts.x, newValue)) {
      base = this.getBaseHeight();
      newValue = rtl ? base - obj.y : obj.y;
      return this.publishPos(base, this.opts.miny, this.opts.maxy, this.opts.y, newValue);
    }
  }

  publishPos(base, min, max, key, newValue) {
    if ((key != null) && (newValue != null)) {
      let oldValue = this.widget.get(key);
      if (oldValue !== newValue) {
        if ((max * base) < newValue) {
          newValue = max * base;
        } else if ((min * base) > newValue) {
          newValue = min * base;
        }
        this.widget.publish(key, `${newValue}px`);
        (this.callback)();
        return true;
      }
    }
  }
}

rh.registerDataAttr('resize', Resize);
