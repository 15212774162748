let { _ } = window.rh;
let { consts } = window.rh;


_.mapToEncodedString = function(map, explodeKey, mapKey) {
  if (explodeKey == null) { explodeKey = '&'; }
  if (mapKey == null) { mapKey = '='; }
  return _.reduce(map, function(result, value, key) {
    if (value != null) {
      if (result.length > 0) { result += explodeKey; }
      result += `${key}${mapKey}${encodeURIComponent(value)}`;
    }
    return result;
  }
  , '');
};

_.encodedStringToMap = function(string, explodeKey, mapKey) {
  if (explodeKey == null) { explodeKey = '&'; }
  if (mapKey == null) { mapKey = '='; }
  let map = _.explodeAndMap(string, explodeKey, mapKey, {default: ''});
  _.each(map, (value, key) =>
  { 
    try
    {
      map[key] = decodeURIComponent(value)
     }
    catch (e)
    { 
      map[key] = value
    }
  });
  return map;
};

_.urlParams = function(query) {
  if (query == null) { query = location.search.substring(1); }
  return _.encodedStringToMap(query);
};

_.urlParam = function(key, query) {
  if (query == null) { query = location.search.substring(1); }
  return key && _.urlParams(query)[key];
};

_.hashParams = function(hash) {
  if (hash == null) { hash = location.hash.substring(1); }
  return _.encodedStringToMap(hash);
};

_.hashParam = key => key && _.hashParams()[key];

_.updateHashMap = function(changeMap, addToHistory) {
  let newMap = _.extend({}, _.hashParams(), changeMap);
  _.each(newMap, function (value, key) {
    if (value === "" || value === null) {
      delete newMap[key];
    }
  })
  let hash = _.mapToEncodedString(newMap);
  if (hash.length > 0) { hash = `#${hash}`; }

  if (addToHistory) {
    return location.hash = hash;
  } else if ((hash !== '') && (location.hash !== hash)) {
    return location.replace(hash);
  }
};

_.queueUpdateHashMap = (hashMap, addToHistory) => _.defer(() => _.updateHashMap(hashMap, addToHistory));

_.stripStringBetween = function(str, startChar, endChar) {
  let newStr;
  let start = str.indexOf(startChar);
  if (start !== -1) {
    let end = str.indexOf(endChar);
    if (end < start) { end = str.length; }
    newStr = `${str.substring(0, start)}${str.substring(end, str.length)}`;
  }
  return newStr || str;
};

_.stripParam = function(url) {
  if (url == null) { url = document.location.href; }
  return _.stripStringBetween(url, '?', '#');
};

_.stripBookmark = function(url) {
  if (url == null) { url = document.location.href; }
  return _.stripStringBetween(url, '#', '?');
};

_.extractStringBetween = function(str, startChar, endChar) {
  let substring;
  let start = str.indexOf(startChar);
  if (start !== -1) {
    let end = str.indexOf(endChar);
    if (end < start) { end = str.length; }
    substring = str.substring(start + 1, end);
  }
  return substring || '';
};

_.extractParamString = function(url) {
  if (url == null) { url = document.location.href; }
  return _.extractStringBetween(url, '?', '#');
};

_.extractHashString = function(url) {
  if (url == null) { url = document.location.href; }
  return _.extractStringBetween(url, '#', '?');
};


//#####
// pathTraverseTo(fromPath, toPath)
// Takes in two absolute paths and simulates
// traversal from fromPath to toPath.
// Returns the steps neeed to traverse from
// fromPath to toPath.
//#####
// TODO: Complete this method
_.traverseToPath = (fromPath, toPath) => '';

let processPathUnit = function(fullPath, pathUnit, separator) {
  if (separator == null) { separator = '/'; }
  switch (pathUnit) {
    case '.': return fullPath;
    case '..': return fullPath.substring(0, fullPath.lastIndexOf(separator));
    default: return fullPath + separator + pathUnit;
  }
};

//#####
// pathTraverseBy(fromPath, traverseBy)
// Takes in two path components and simulates
// traversal from fromPath by traverseBy.
// Returns the resulting path after the traversal.
// Eg: 'C:/a/b/c/', '../../' retuns 'C:/a/'
//#####
_.traverseByPath = function(fromPath, traverseBy, separator) {
  if (separator == null) { separator = '/'; }
  fromPath = fromPath.substring(0, fromPath.lastIndexOf(separator));
  let parts = traverseBy.split(separator);

  for (let part of Array.from(parts)) {
    if (part.length > 0) {
      fromPath = processPathUnit(fromPath, part, separator);
    }
  }

  return fromPath + separator;
};

_.scheme = function(url) {
  let scheme;
  let index = url.indexOf(':');
  if (index !== -1) { scheme = url.substring(0, index + 1).toLowerCase().trim(); }
  return scheme;
};
  
_.protocol = function(url) {
  let protocol;
  let index = url.trim().indexOf(':');
  if (index !== -1) { protocol = url.substring(0, index + 1).toLowerCase(); }
  if (protocol) {
    let match = protocol.match(/^[a-z]+:/);
    if (!match || (match[0].length !== protocol.length)) { protocol = undefined; }
  }
  return protocol;
};

_.isInternal = urlName =>
  (urlName.indexOf('//') !== 0) && (urlName.indexOf('/&#47;') !== 0) &&
  (urlName.indexOf('&#47;/') !== 0) && (urlName.indexOf('&#47;&#47;') !== 0)
;

_.isJavaScriptUrl = url => 'javascript:' === _.scheme(url);

_.isRelativeUrl = url => !_.scheme(url) && url.trim().indexOf('/');

_.isValidFileUrl = function(url) {
  if (url[0] === '#') { return false; }
  let scheme = _.scheme(url);
  return !scheme || (['http:', 'https:', 'ftp:', 'file:'].indexOf(scheme) !== -1);
};

_.makeRelativeUrl = function(absUrl, baseUrl) {
  if (baseUrl == null) { baseUrl = decodeURI(document.location.href); }
  if (absUrl === baseUrl) { return ''; }
  let absPath = _.filePath(absUrl);
  let basePath = _.filePath(baseUrl);
  let relPath = _.makeRelativePath(absPath, basePath);
  return `${relPath}${absUrl.substring(absPath.length)}`;
};

_.makeRelativePath = function(absUrl, baseUrl) {
  let relUrl;
  if (baseUrl == null) { baseUrl = _.filePath(); }
  if (absUrl && !_.isRelativeUrl(absUrl) && !_.isRelativeUrl(baseUrl)) {
    let srcParts = absUrl.split('/');
    let baseParts = baseUrl.split('/');
    let idx = 0;
    while (true) {
      if ((srcParts.length === idx) || (baseParts.length === idx)) { break; }
      if (srcParts[idx] !== baseParts[idx]) { break; }
      idx++;
    }
    
    let relParts = srcParts.slice(idx);
    relUrl = '';
    let dotdotcount = baseParts.length - idx - 1;
    while (true) {
      if (dotdotcount <= 0) { break; }
      relUrl += '../';
      dotdotcount--;
    }
    relUrl += relParts.join('/');
  } else {
    relUrl = absUrl;
  }
  return relUrl;
};

_.makeFullUrl = function(relUrl, parentPath) {
  if (parentPath == null) { parentPath = rh._.parentPath(); }
  if (_.isRelativeUrl(relUrl)) {
    return window._getFullPath(parentPath, relUrl);
  } else {
    return relUrl;
  }
};

_.isLocal = () => window.location.protocol === 'file:';

_.isRemote = () => window.location.protocol !== 'file:';

let curOrigin = null;
_.isSameOrigin = function(origin) {
  if (_.isLocal()) { return true; }
  let { location } = window;
  if (curOrigin == null) { curOrigin = location.origin; }
  if (curOrigin == null) {
    curOrigin = `${location.protocol}//${location.hostname}`;
    if (location.port) { curOrigin += `:${location.port}`; }
  }
  return curOrigin === origin;
};

_.filePath = function(url) {
  if (url == null) { url = decodeURI(document.location.href); }
  let index = url.indexOf('?');
  if (index !== -1) { url = url.substring(0, index); }
  index = url.indexOf('#');
  if (index !== -1) { url = url.substring(0, index); }
  return url;
};

_.parentPath = function(filePath) {
  if (filePath == null) { filePath = _.filePath(); }
  let index = filePath.lastIndexOf('/');
  if (index !== -1) { filePath = filePath.substring(0, index + 1); }
  return filePath;
};

_.getFileName = function(absUrl) {
  let filePath = _.filePath(absUrl);
  let idx = filePath.lastIndexOf('/');
  let fiileName = idx !== -1 ? filePath.substring(idx + 1) : filePath;
  return fiileName || '';
};

_.getFileExtention = function(absUrl) {
  let ext;
  let fiileName = _.getFileName(absUrl);
  let idx = fiileName != null ? fiileName.lastIndexOf('.') : undefined;
  if (idx !== -1) { ext = fiileName.substring(idx); }
  return ext || '';
};
