let { rh } = window;

rh.registerDataAttr  = function(attrName, DataAttrHandler, Widget) {
  if (Widget == null) { ({ Widget } = rh); }
  let methodName = `data_${attrName}`;
  Widget.prototype.dataAttrMethods[`data-${attrName}`] = methodName;
  Widget.prototype.dataAttrs.push(attrName);
  return Widget.prototype[methodName] = function(node, attrValue) {
    return new DataAttrHandler(this, node, attrValue);
  };
};
