let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { model } = rh;

let loadWidgets = (parentNode, parent) =>
  _.each($.find(parentNode, '[data-rhwidget]'), function(node) {
    if ($.dataset(node, 'loaded')) { return; } //it can be empty string on old browser
    if (!$.isDescendent(parentNode, node)) { return; } //ignore nested widget data
    let config = $.dataset(node, 'config');
    config = config ? _.resolveNiceJSON(config) : {};
    return _.each(_.resolveWidgetArgs($.dataset(node, 'rhwidget')), function(wInfo) {
      let {wName, wArg, pipedArgs, rawArg} = wInfo;
      if (wName[0] === wName[0].toLowerCase()) { //data widget
        config.rawArg = rawArg;
      } else {
        if (pipedArgs.length > 0) { config.pipedArgs = pipedArgs; }
        if (wArg) { _.extend(config, wArg); }
      }
      config.node = node;
      let wclass = rh.widgets[wName];
      let widget = new wclass(config);
      return widget.init(parent);
    });
  })
;
  
//data-rhtags is synthatic suger(shortcut) for data-rhwidgets='ContentFilter' and
// data-config='{"id": "1"}'
let loadContentFilter = parentNode =>
  (() => {
    let result = [];
    for (let node of Array.from($.find(parentNode, '[data-rhtags]'))) {
      var widget;
      if (!$.isDescendent(parentNode, node)) { continue; } //ignore nested widget data
      let config = $.dataset(node, 'config');
      config = config ? _.resolveNiceJSON(config) : {};
      config.ids = $.dataset(node, 'rhtags').split(',');
      config.node = node;
      result.push(widget = new rh.widgets.ContentFilter(config));
    }
    return result;
  })()
;

let loadDataHandlers = function(parentNode, parent) {
  loadWidgets(parentNode, parent);
  return loadContentFilter(parentNode);
};

_.loadWidgets = loadWidgets;
_.loadContentFilter = loadContentFilter;
_.loadDataHandlers = loadDataHandlers;
