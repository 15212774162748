let { _ } = window.rh;


_.stackTrace = function() {
  let err = new Error();
  return err.stack;
};

_.safeExec = fn =>
  function() {
    try {
      return fn.apply(this, arguments);
    } catch (error) {
      if (rh._debug) { rh._d('error', `Function: ${fn}`, error.message); }
      return undefined;
    }
  }
;
