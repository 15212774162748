let { _ } = window.rh;


_.addEventListener = function(obj, eventName, callback) {
  if (obj == null) { obj = window; }
  if (obj.addEventListener != null) {
    return obj.addEventListener(eventName, callback, false);
  } else if (obj.attachEvent != null) {
    return obj.attachEvent(`on${eventName}`, callback);
  }
};

_.removeEventListener = function(obj, eventName, callback) {
  if (obj == null) { obj = window; }
  if (obj.removeEventListener != null) {
    return obj.removeEventListener(eventName, callback, false);
  } else if (obj.detachEvent != null) {
    return obj.detachEvent(`on${eventName}`, callback);
  }
};

_.isTouchEnabled = () => 'ontouchstart' in document.documentElement;

_.preventDefault = function(e) {
  if (e.preventDefault != null) {
    return e.preventDefault();
  } else {
    return e.returnValue = false;
  }
};

_.mouseButton = function(e) {
  if ('buttons' in e) {
    return e.buttons;
  } else if ('which' in e) {
    return e.which;
  } else {
    return e.button;
  }
};

_.initMouseMove = (function() {
  let initDone = false;
  return function() {
    if (!initDone) {
      initDone = true;
      return _.addEventListener(document, 'mousemove', function(e) {
        if (!e.defaultPrevented) {
          let obj = {x: e.clientX, y: e.clientY, which: _.mouseButton(e)};
          rh.model.publish(rh.consts('EVT_MOUSEMOVE'), obj, {sync: true});
          if (obj.defaultPrevented) { return _.preventDefault(e); }
        }
      });
    }
  };
})();

_.getScroll = function () {
  if (window.pageYOffset != undefined) {
    return [pageXOffset, pageYOffset];
  } else {
    var sx, sy, d = document,
      r = d.documentElement,
      b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
  }
}

_.initTouchEvent = (function() {
  let initDone = false;
  return function() {
    if (!initDone && _.isTouchEnabled()) {
      let x, y, y1;
      initDone = true;
      let x1 = (y1 = (x = (y = 0)));
  
      let calculateDirection = _.debounce(function() {
        let direction;
        let angle = Math.atan((y1 - y) / (x1 - x));
        if (x1 > x) {
          direction =
            angle > (Math.PI / 4) ?
              'down'
            : angle < (-Math.PI / 4) ?
              'up'
            :
              'right';
        } else {
          direction =
            angle > (Math.PI / 4) ?
              'up'
            : angle < (-Math.PI / 4) ?
              'down'
            :
              'left';
        }
        rh.model.publish('.touchmove', {x, y, x1, y1});
        rh.model.publish(rh.consts('EVT_SWIPE_DIR'), direction, {sync: true});
        rh.model.publish(rh.consts('EVT_SWIPE_DIR'), null);
        return x = (y = 0);
      }
      , 200);

      return _.addEventListener(document, 'touchmove', function(e) {
        x1 = (e.touches[0] != null ? e.touches[0].pageX : undefined) || 0;
        y1 = (e.touches[0] != null ? e.touches[0].pageY : undefined) || 0;
        if ((x === 0) && (y === 0)) {
          x = x1;
          y = y1;
        }

        calculateDirection();
        return _.preventDefault(e);
      });
    }
  };
})();
