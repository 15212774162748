let rh = require('../../lib/rh')
let _ = rh._

_.max = arr => {
  let max = arr && arr[0]
  _.each(arr, item => {
    if(item > max) {
      max = item
    }
  })
  return max
}

_.min = arr => {
  let min = arr && arr[0]
  _.each(arr, item => {
    if(item < min) {
      min = item
    }
  })
  return min
}
