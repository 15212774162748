let { rh } = window;
let { $ } = rh;
let { _ } = rh;

class NodeHolder {

  constructor(nodes) {
    this.nodes = nodes;
  }

  isVisible(node) {
    if (node == null) { node = this.nodes[0]; }
    return !$.hasClass(node, 'rh-hide');
  }

  show() {
    return _.each(this.nodes, function(node) {
      if (!this.isVisible(node)) {
        $.removeClass(node, 'rh-hide');
        return node.hidden = false;
      }
    }
    , this);
  }

  hide() {
    return _.each(this.nodes, function(node) {
      if (this.isVisible(node)) {
        $.addClass(node, 'rh-hide');
        return node.hidden = true;
      }
    }
    , this);
  }
  
  accessible(flag) {
    return _.each(this.nodes, node => node.hidden = flag);
  }

  updateClass(newClasses) {
    if (this.oldClasses == null) { this.oldClasses = []; }
    for (let node of Array.from(this.nodes)) {
      for (var className of Array.from(this.oldClasses)) { $.removeClass(node, className); }
      for (className of Array.from(newClasses)) {
        if (className.length > 0) {
          $.addClass(node, className);
          this.oldClasses.push(className);
        }
      }
    }
  }

  updateNodes(newNodes) {
    let firstNode = this.nodes[0];
    let { parentNode } = firstNode;
    for (var node of Array.from(newNodes)) { parentNode.insertBefore(node, firstNode); }
    for (node of Array.from(this.nodes)) { parentNode.removeChild(node); }
    return this.nodes = newNodes;
  }
}

rh.NodeHolder = NodeHolder;