let rh = require('../../lib/rh')

class OnLoad {
  constructor(widget, node, rawExpr) {
    let {callback} = widget.resolveRawExprWithValue(rawExpr)
    node.onload = () => callback.call(widget)
  }
}

rh.registerDataAttr('onload', OnLoad)
