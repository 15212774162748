let { _ } = window.rh;

//Regular Expressions

//Ex: "abc #{var1}"
let enclosedVarRegx = /\#{([^}]*)\}/g;
let userVarRegx = /\$([_a-zA-Z][_a-zA-Z0-9]*)/g;
let regxStringRegx = /\B\/([^\/]*)\//g;

_.toRegExp = function(str) {
  let regx;
  if (!str || !_.isString(str)) { return str; }
  let matches = str.match(regxStringRegx);
  let match = matches && matches[0];
  if (match) {
    let pattern = match.substring(1, match.length - 1);
    let flag = str.substring(match.length);
    regx = new RegExp(pattern, flag);
  }
  return regx || str;
};

_.splitAndTrim = function(string, splitKey) {
  if (string == null) { string = ''; }
  return _.map(string.split(splitKey), value => value.trim());
};

/*
 * Explodes a string based on explodeKey then
 * creates a map using the exploded strings by splitting them further on mapKey
 */
_.explodeAndMap = function(string, explodeKey, mapKey, opts) {
  if (string == null) { string = ' '; }
  if (opts == null) { opts = {}; }
  let pairs = string.split(explodeKey);
  let regex = new RegExp(`${mapKey}(.+)?`);
  let map = {};

  for (let rawPair of Array.from(pairs)) {
    let pair = rawPair.split(regex);
    let key = pair[0].trim();
    let value = pair[1];

    if (opts.caseInsensitive) { key = key.toLowerCase(); }
    if (opts.trim) { value = value && value.trim(); }
    if ((opts.default != null) && (value == null)) { value = opts.default; }

    if (key !== '') { map[key] = value; }
  }
  return map;
};

_.resolveNamedVar = function(expr) {
  let matches;
  if (matches = expr.match(userVarRegx)) {
    for (let match of Array.from(matches)) {
      expr = expr.replace(match, `this.user_vars.${match.substring(1)}`);
    }
  }
  return expr;
};

_.resolveEnclosedVar = function(expr, callback, context) {
  let matches;
  if (context == null) { context = this; }
  if (matches = expr.match(enclosedVarRegx)) {
    for (let match of Array.from(matches)) {
      let name = match.substring(2, match.length - 1).trim();
      let value = callback.call(context, name);
      expr = expr.replace(match, (value != null) ? value : '');
    }
  }
  return expr;
};

// use '.' as attrib name to pass opts for attrs data
_.resolveAttr = string =>
  _.reduce(_.explodeAndMap(string, ';', ':'), function(r, v, k) {
    _.each(k.split(','), key => r[key.trim()] = v);
    return r;
  }
  , {})
;

_.resolveNiceJSON = function(string) {
  if (string == null) { string = ''; }
  string = (string.trim)();
  if (!string) { return {}; }
  if (string[0] === '{') {
    return JSON.parse(string);
  } else {
    string = string.replace(/'/g, '"');
    string = `{${string}}`;
    return JSON.parse(string.replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'));
  }
};

_.resolvePipedExpression = function(string) {
  if (string == null) { string = ''; }
  let concatNext = false;
  return _.reduce(string.split('|'), function(result, item) {
    let mergedItem;
    if (concatNext && (result.length > 0)) {
      mergedItem = `${result[result.length - 1]} ||${item}`;
      result.length = result.length - 1;
    }
    
    concatNext = item.length === 0;
    if (mergedItem) { item = mergedItem; }
    
    if (item.length !== 0) { result.push(item.trim()); }
    return result;
  }
  , []);
};

_.resolveLoopExpr = function(config) {
  let value = config.split(':');
  if (value.length > 1) {
    let vars = _.splitAndTrim(value.shift(), ',');
    return {expr: value[0], index: vars[0], item: vars[1]};
  } else {
    return {expr: value[0]};
  }
};

_.resolveWidgetArgs = function(rawArgs) {
  let pairs = rawArgs.split(';');
  return _.map(pairs, function(pair) {
    let wArg;
    let pipedArgs = _.resolvePipedExpression(pair);
    let args = (pipedArgs.shift)() || '';
    args = args.split(/:(.+)?/);
    let wName = args[0].trim();
    let rawArg = pair.substring(wName.length).trim();
    if (rawArg[0] === ':') { rawArg = rawArg.substring(1); }
    if (wArg = args[1]) {
      if (-1 !== wArg.search(':')) {
        wArg = _.explodeAndMap(wArg, ',', ':', {trim: true});
      } else {
        wArg = {arg: wArg};
      }
    }
    return {wName, wArg, pipedArgs, rawArg};
});
};

_.resolveExprOptions = function(rawArgs) {
  let opts;
  let values = _.resolvePipedExpression(rawArgs);
  if (values[1]) { opts = _.resolveNiceJSON(values[1]); }
  return {expr: values[0], opts};
};

_.resolveInputKeys = function(rawArgs) {
  let opts;
  let values = _.resolvePipedExpression(rawArgs);
  if (values[1]) { opts = _.resolveNiceJSON(values[1]); }
  let keys = _.explodeAndMap(values[0], ',', ':', {trim: true});
  return {keys, opts};
};

_.applyCallbackOptions = function(callback, opts) {
  let newCallback = callback;
  if (opts && opts.debounce) {
    newCallback = _.debounce(newCallback, opts.debounce);
  }
    
  if (opts && opts.toggleTimeout) {
    newCallback = _.toggleTimeout(newCallback, opts.toggleTimeout);
  }

  if (opts && opts.timeout) {
    newCallback = _.timeout(newCallback, opts.timeout);
  }

  if (opts && opts.defer) {
    newCallback = _.timeout(newCallback, 1);
  }

  return newCallback;
};

_.parseInt = function(string, defaultValue, base) {
  if (base == null) { base = 10; }
  if ((string != null) && (string !== '')) {
    return parseInt(string, base);
  } else if (defaultValue != null) {
    return defaultValue;
  } else {
    return string;
  }
};
