let { rh } = window;
let { _ } = rh;

// Storage class to persist key value pairs to localDB/cookies
class Storage {

  toString() { return 'Storage'; }

  init(namespace) {
    if (this.namespace) {
      if (rh._debug && (this.namespace !== namespace)) {
        return rh._d('error', 'Storage', 'Namespace cann\'t be changed');
      }
    } else {
      let jsonString;
      this.namespace = namespace;
      if (_.canUseLocalDB()) {
        jsonString = localStorage.getItem(this.namespace);
      } else {
        let rawData = _.explodeAndMap(document.cookie, ';', '=');
        if (rawData[this.namespace]) { jsonString = unescape(rawData[this.namespace]); }
      }
      return this.storageMap = jsonString ? JSON.parse(jsonString) : {};
    }
  }

  isValid() {
    if (rh._debug && !this.storageMap) {
      rh._d('error', 'Storage', 'Namespace is not set yet.');
    }
    return (this.storageMap != null);
  }

  persist(key, value) {
    if (this.isValid()) {
      this.storageMap[key] = value;
      return this.dump();
    }
  }

  fetch(key) { if (this.isValid()) { return this.storageMap[key]; } }

  dump() {
    if (this.isValid()) {
      if (_.canUseLocalDB()) {
        return localStorage.setItem(this.namespace, JSON.stringify(this.storageMap));
      } else {
        return document.cookie = `${this.namespace}=${escape(JSON.stringify(this.storageMap))}`;
      }
    }
  }
}

rh.Storage = Storage;
rh.storage = new Storage();
