let { rh } = window;
let { _ } = rh;
let { $ } = rh;

let defaultOpts =
  {async: true};

let formData = (rh.formData = function(opts) {
  let form_data = new window.FormData;
  _.each(opts, (value, key) => form_data.append(key, value));
  return form_data;
});

//private class of http api
class Response {

  constructor(xhr, opts) {
    this.onreadystatechange = this.onreadystatechange.bind(this);
    this.xhr = xhr;
    this.opts = opts;
    if (this.opts.success != null) { this.success(this.opts.success); }
    if (this.opts.error != null) { this.error(this.opts.error); }
    this.xhr.onreadystatechange = this.onreadystatechange;
  }
    
  onreadystatechange() {
    if (this.xhr.readyState !== 4) { return; }

    let text = this.xhr.responseText;
    let { status } = this.xhr;
    let headers = name => this.xhr.getResponseHeader(name);
    
    if (this.isSuccess(status)) {
      if (this.successFn) { this.successFn(text, status, headers, this.opts); }
    } else {
      if (this.errorFn) { this.errorFn(text, status, headers, this.opts); }
    }

    if (this.finallyFn) { return this.finallyFn(text, status, headers, this.opts); }
  }

  isSuccess(status) { return ((status >= 200) && (status < 300)) || (status === 304); }

  success(fn) {
    this.successFn = fn;
    return this;
  }

  error(fn) {
    this.errorFn = fn;
    return this;
  }

  finally(fn) {
    this.finallyFn = fn;
    return this;
  }
}
  
let createRequest = function(opts) {
  let XHR = window.XMLHttpRequest || window.ActiveXObject('Microsoft.XMLHTTP');
  let xhr = new XHR;
  let response = new Response(xhr, opts);
  return {xhr, response};
};

// http apis
let http = (rh.http = function(opts) {
  opts = _.extend({}, defaultOpts, opts);
  let {xhr, response} = createRequest(opts);
  xhr.open(opts.method, opts.url, opts.async);
  
  if (opts['Content-type']) {
    xhr.setRequestHeader('Content-type', opts['Content-type']);
  }

  xhr.send(opts.data);
  return response;
});

http.get = (url, opts) => http(_.extend({url, method: 'GET'}, opts));

http.post = (url, data, opts) => http(_.extend({url, method: 'POST', data}, opts));

http.put = (url, data, opts) => http(_.extend({url, method: 'PUT', data}, opts));

http.jsonp = function(url, opts) {
  opts = _.extend({}, defaultOpts, opts);
  let node = $('script', 0) || document.head.children[0];
  let newNode = document.createElement('script');
  newNode.async = opts.async;
  newNode.src = url;
  return node.parentNode.insertBefore(newNode, node);
};
