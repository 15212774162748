let consts;
let { rh } = window;
let cache = {};

rh.consts = (consts = function(key, value) {
  if (arguments.length === 1) {
    if (rh._debug) {
      if (!(key in cache)) { rh._d('error', 'consts', `${key} is not available`); }
    }
    return cache[key];
  } else if (key in cache) {
    if (rh._debug) { return rh._d('error', 'consts', `${key} is already registered`); }
  } else {
    return cache[key] = value;
  }
});

// Temp keys
consts('KEY_TEMP_DATA',                  '.temp.data');
consts('KEY_SHOW_TOC_BACK_BUTTON', '.p.show_toc_back_button');
// iframe keys
consts('KEY_SHARED_INPUT',              '._sharedkeys.input');
consts('KEY_SHARED_OUTPUT',             '._sharedkeys.output');
consts('KEY_IFRAME_EVENTS',             '.l.iframe_events');

// Screen specific
consts('KEY_SCREEN',                    '.l.screen');
consts('KEY_DEFAULT_SCREEN',            '.l.default_screen');
consts('KEY_SCREEN_NAMES',              '.l.screen_names');
consts('KEY_SCREEN_DESKTOP',
       `${consts('KEY_SCREEN')}.desktop.attached`);
consts('KEY_SCREEN_TABLET',
  `${consts('KEY_SCREEN')}.tablet.attached`);
consts('KEY_SCREEN_TABLET_PORTRAIT',
  `${consts('KEY_SCREEN')}.tablet_portrait.attached`);
consts('KEY_SCREEN_PHONE',
  `${consts('KEY_SCREEN')}.phone.attached`);
consts('KEY_SCREEN_IOS',
  `${consts('KEY_SCREEN')}.ios.attached`);
consts('KEY_SCREEN_IPAD',
  `${consts('KEY_SCREEN')}.ipad.attached`);
consts('KEY_SCREEN_PRINT',
  `${consts('KEY_SCREEN')}.print.attached`);
// Events
consts('KEY_IS_RESPONSIVE',         '.e.isresponsive');
consts('EVT_ORIENTATION_CHANGE',         '.e.orientationchange');
consts('EVT_HASH_CHANGE',                '.e.hashchange');
consts('EVT_WIDGET_BEFORELOAD',          '.e.widget_beforeload');
consts('EVT_WIDGET_LOADED',              '.e.widget_loaded');
consts('EVT_BEFORE_UNLOAD',              '.e.before_unload');
consts('EVT_UNLOAD',                     '.e.unload');
consts('EVT_MOUSEMOVE',                  '.e.mousemove');
consts('EVT_SWIPE_DIR',                  '.e.swipe_dir');
consts('EVT_FAST_CLICK',                 '.e.fast_click');
consts('EVT_CLICK_INSIDE_IFRAME',        '.e.click_inside_iframe');
consts('EVT_SCROLL_INSIDE_IFRAME',       '.e.scroll_inside_iframe');
consts('EVT_INSIDE_IFRAME_DOM_CONTENTLOADED',
                                         'e.inside_iframe_dom_contentloaded');
consts('EVT_RESIZE',                     '.e.resize');                                         
consts('RHMAPNO',                         'rhmapno');
consts('RHWINDOW',                        'rhwnd');
consts('RHCSHMODE',                       'rhcsh');
consts('TOPIC_FILE',                      'topic.htm');
consts('HOME_PAGE',                       'index.htm');
