let { rh } = window;
let { _ } = rh;

class Plugin {

  attachOwner(obj) {
    if (this._ownerFns == null) { this._ownerFns = {}; }
    if (this.hasOwner()) { this.detach(this.owner); }
    this.owner = obj;
    if (this._overrideNames) { for (let fnName of Array.from(this._overrideNames)) { this._overrideOwnerFn(fnName); } }
    return this.ownerIsChanged();
  }

  detachOwner() {
    if (this.hasOwner()) {
      if (this._ownerFns) { for (let fnName in this._ownerFns) { this._restoreOwnerFn(fnName); } }
      this.owner = null;
      this._ownerFns = {};
      return this.ownerIsChanged();
    }
  }

  // plugin should override this method to get the notification of owoner change
  ownerIsChanged() {}

  hasOwner() { return (this.owner != null); }

  addOverrides(fnNames) {
    if (this._overrideNames == null) { this._overrideNames = []; }
    return (() => {
      let result = [];
      for (let fnName of Array.from(fnNames)) {
        this._overrideNames.push(fnName);
        result.push(this._overrideOwnerFn(fnName));
      }
      return result;
    })();
  }

  removeOverrides(fnNames) {
    return (() => {
      let result = [];
      for (let fnName of Array.from(fnNames)) {
        this._restoreOwnerFn(fnName);
        let index = this._overrideNames.indexOf(fnName);
        if (index > -1) { result.push(this._overrideNames.splice(index, 1)); } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  _overrideOwnerFn(fnName) {
    if (this.hasOwner()) {
      let ownerFn = this.owner[fnName];
      this._ownerFns[fnName] = ownerFn;
      return this.owner[fnName] = function() {
        let args = []; let i = -1;
        while (++i < arguments.length) { args.push(arguments[i]); }
        let bindedFn = newArgs => {
          return __guardMethod__(ownerFn, 'apply', o => o.apply(this.owner, newArgs));
        };
        return this[fnName](bindedFn, args);
      }.bind(this);
    }
  }

  _restoreOwnerFn(fnName) {
    if (this.hasOwner()) {
      this.owner[fnName] = this._ownerFns[fnName];
      return delete this._ownerFns[fnName];
    }
  }
}

rh.Plugin = Plugin;

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}