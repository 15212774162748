let { rh } = window;
let { _ } = rh;

let $ = (rh.$ = function(selector, index) {
  if ((index != null) && (index === 0)) {
    return document.querySelector(selector);
  } else {
    let nodeList = document.querySelectorAll(selector);
    if ((index != null) && (index < nodeList.length)) {
      return nodeList[index];
    } else {
      return nodeList;
    }
  }
});

//arguments
// (parent, selector) ->
// or (selector) ->
$.find = function() {
  let parent, selector;
  if (arguments.length > 1) {
    parent = arguments[0];
    selector = arguments[1];
  } else {
    parent = document;
    selector = arguments[0];
  }
  return parent.querySelectorAll(selector);
};

$.traverseNode = function(node, preChild, postChild, onChild, context) {
  if (context == null) { context = window; }
  if (preChild && preChild.call(context, node)) {
    $.eachChildNode(node, function(child) {
      if (!onChild || onChild.call(context, child)) {
        return $.traverseNode(child, preChild, postChild, onChild, context);
      }
    });
    if (postChild) { postChild.call(context, node); }
  }
  return node;
};

$.eachChildNode = function(parent, fn, context) {
  if (context == null) { context = window; }
  for (let child of Array.from(parent.children)) { fn.call(context, child); }
};

$.eachChild = function(parent, selector, fn, context) {
  if (context == null) { context = window; }
  for (let node of Array.from(this.find(parent, selector))) {
    fn.call(context, node);
  }
};

$.eachDataNode = function(parent, dataAttr, fn, context) {
  if (context == null) { context = window; }
  for (let node of Array.from(this.find(parent, `[data-${dataAttr}]`))) {
    fn.call(context, node, $.dataset(node, dataAttr));
  }
};

$.eachAttributes = function(node, fn, context) {
  let infos = (Array.from(node.attributes).map((attr) => [attr.specified, attr.name, attr.value]));
  let i = -1;
  while (++i < infos.length) { //here length can be increased in between
    let info = infos[i];
    if (info[0] !== false) { fn.call(context || window, info[1], info[2], infos); }
  }
};

$.getAttribute = function(node, attrName) {
  if (node.getAttribute != null) { return node.getAttribute(attrName); }
};

$.setAttribute = function(node, attrName, value) {
  if (node.setAttribute != null) { return node.setAttribute(attrName, value); }
};

$.removeAttribute = function(node, attrName) {
  if (node.removeAttribute != null) { return node.removeAttribute(attrName); }
};

$.hasAttribute = function(node, attrName) {
  if (node.hasAttribute != null) { return node.hasAttribute(attrName); } else { return false; }
};

$.dataset = function(node, attrName, value) {
  if (arguments.length === 3) {
    if (value !== null) {
      return $.setAttribute(node, `data-${attrName}`, value);
    } else {
      return $.removeAttribute(node, `data-${attrName}`);
    }
  } else {
    return $.getAttribute(node, `data-${attrName}`);
  }
};

$.isDescendent = function(parent, child) {
  let node = child.parentNode;
  while (true) {
    if (!node || (node === parent)) { break; }
    node = node.parentNode;
  }
  return node === parent;
};

$.addClass = function(node, className) {
  if (node.classList != null) {
    return node.classList.add(className);
  } else {
    return node.className = `${node.className} ${className}`;
  }
};

$.addStyle = function (node, attribute, value) {
  if (node && node.style) {
    node.style[attribute] = value
  }
}

$.removeClass = function(node, className) {
  if (node.classList != null) {
    return node.classList.remove(className);
  } else {
    return node.className = node.className.replace(className, '');
  }
};

$.hasClass = function(node, className) {
  if (node.classList != null) {
    return node.classList.contains(className);
  } else if (node.className) {
    return node.className.match(new RegExp(`${className}($| )`)) !== null;
  }
};

$.toggleClass = function(node, className) {
  if ($.hasClass(node, className)) {
    return $.removeClass(node, className);
  } else {
    return $.addClass(node, className);
  }
};

$.computedStyle = node => node.currentStyle || window.getComputedStyle(node, null);

$.isElementNode = node => node && (node.nodeType === Node.ELEMENT_NODE)

$.isVisibleNode = function(node) {
  let computedStyle = $.computedStyle(node);
  return ('none' !== computedStyle['display']) &&
  !_.isZeroCSSValue(computedStyle['opacity']) &&
  !_.isZeroCSSValue(computedStyle['max-height']);
};

$.textContent = function(node, content) {
  if (arguments.length === 2) {
    if (node.textContent != null) {
      return node.textContent = content;
    } else {
      return node.innerText = content;
    }
  } else {
    return node.textContent || node.innerText;
  }
};

$.innerHTML = function(node, content) {
  if (arguments.length === 2) {
    return node.innerHTML = content;
  } else {
    return node.innerHTML;
  }
};

$.css = function(node, styleName, value) {
  if (arguments.length === 3) {
    return node.style[styleName] = value;
  } else {
    return node.style[styleName];
  }
};

$.nodeName = node => node.nodeName;

$.pageHeight = function() {
  let height;
  let de = document.documentElement;
  if (de) { height = de.scrollHeight || de.clientHeight || de.offsetHeight; }
  if (!height) { height = window.innerHeight; }
  let { body } = document;
  let bodyHeight = body.scrollHeight || body.clientHeight || body.offsetHeight;
  height = Math.max(height, bodyHeight);
  return `${height}px`;
};

$.pageWidth = function () {
  let width;
  let de = document.documentElement;
  if (de) { width = de.scrollWidth || de.clientWidth || de.offsetWidth; }
  if (!width) { width = window.innerWidth; }
  let { body } = document;
  let bodyWidth = body.scrollWidth || body.clientWidth || body.offsetWidth;
  width = Math.max(width, bodyWidth);
  return `${width}px`;
}

$.createElement = function(tag, innerHtml) {
  let tagNode = document.createElement(tag);
  tagNode.innerHTML = innerHtml;
  return tagNode;
};

export default $