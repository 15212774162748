let { _ } = window.rh;

// Regular Expressions

// Ex: @key('wow') => this.publish('key', 'wow');
let publishRegx = /(^|[^\\])@([\w\.]*)\((.*)\)/;

// Ex: x = @key => x = this.get('key');
let getRegx = /(^|[^\\])@([\w\.]*)/;

// Ex: x = @KEY => x = rh.consts('KEY')
let modelConstsRegx = /@([A-Z][_A-Z0-9]*)/;


_.resolvePublish = function(expression) {
  let regex = new RegExp(publishRegx);
  while (true) {
    let match = regex.exec(expression);
    if (!match) { break; }
    expression = expression.replace(match[0],
      `${match[1]} this.publish('${match[2]}', ${match[3]})`);
  }
  return expression;
};

_.resolveGet = function(expression, keys) {
  let regex = new RegExp(getRegx);
  while (true) {
    let match = regex.exec(expression);
    if (!match) { break; }
    if (keys && (-1 === keys.indexOf(match[2]))) { keys.push(match[2]); }
    expression = expression.replace(match[0],
      `${match[1]} this.get('${match[2]}')`);
  }
  return expression;
};

_.resolveModelConst = function(expression) {
  let subexp = '';
  let regex = new RegExp(modelConstsRegx);
  while (true) {
    let match = regex.exec(expression);
    if (!match) { break; }
    let key = rh.consts(match[1]);
    if (key != null) {
      expression = expression.replace(match[0], `@${key}`);
    } else {
      let index = match.index + match[1].length + 1;
      subexp += expression.substring(0, index);
      expression = expression.substring(index);
    }
  }
  return subexp + expression;
};

_.resolveModelKeys = function(expression, keys) {
  return this.resolveGet(this.resolvePublish(this.resolveModelConst(expression)), keys);
};

_.isValidModelKey = function(key) {
  if ((key === 'true') || (key === 'false')) { return false; }
  let match = key.match(/[._a-zA-Z][._a-zA-Z0-9 ]*/);
  return match && (match[0] === key);
};

_.isValidModelConstKey = function(key) {
  let match = key.match(/[A-Z][_A-Z0-9]*/);
  return match && (match[0] === key);
};

_.get = function(obj, itemKey) {
  let value;
  let keys = itemKey.split('.');
  for (let index = 0; index < keys.length; index++) {
    let key = keys[index];
    if (index === 0) {
      value = obj[key];
    } else if (value) {
      value = value[key];
    } else {
      break;
    }
  }
  return value;
};

_.groupBy = function(array, keyOrIterator) {
  var iterator, key
  if (typeof key !== 'function') {
    key = String(keyOrIterator)
    iterator = function (item) { return item[key] }
  } else {
    iterator = keyOrIterator
  }
  return array.reduce(function (memo, item) {
    var key = iterator(item)
    memo[key] = memo[key] || []
    memo[key].push(item)
    return memo
  }, {})
}

_.isScreenAttached = scrrenName => true === rh.model.get(`${rh.consts('KEY_SCREEN')}.${scrrenName}.attached`);

_.parentKey = function(fullKey) {
  let keys = fullKey.split('.');
  (keys.pop)();
  return keys.join('.');
};

_.lastKey = function(fullKey) {
  let keys = fullKey.split('.');
  return keys[keys.length - 1];
};

_.splitKey = function(fullKey) {
  let keys = fullKey.split('.');
  let key = (keys.pop)();
  let parentKey = keys.join('.');
  return {key, parentKey};
};
