let { rh } = window;
let { _ } = rh;
let { $ } = rh;

class Table {

  constructor(widget, node, key) {
    this.widget = widget;
    this.node = node;
    this.widget.publish(key, this.extractRowColumnMatrix(this.node));
  }
 
  extractRowColumnMatrix(node) {
    let rowColMatrix = [];
    let rowElements = [];
    this.widget.traverseNode(node, function(node) {
      if ('TD' === $.nodeName(node)) {
        rowElements.push($.innerHTML(node));
        return false;
      } else if ('TR' === $.nodeName(node)) {
        if (rowElements.length !== 0) { rowColMatrix.push(rowElements); }
        rowElements = [];
      }
      return true;
    });
    if (rowElements.length !== 0) { rowColMatrix.push(rowElements); }
    return rowColMatrix;
  }
}

rh.registerDataAttr('table', Table);
