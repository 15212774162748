let { rh } = window;
let { _ } = rh;
let { consts } = rh;
let { model } = rh;

let defaultScreens = {
  desktop: {
    media_query: 'screen and (min-width: 1296px)'
  },
  tablet: {
    media_query: 'screen and (min-width: 942px) and (max-width: 1295px)'
  },
  phone: {
    media_query: 'screen and (max-width: 941px)'
  },
  ios: {
    user_agent: /(iPad|iPhone|iPod)/g
  },
  ipad: {
    user_agent: /(iPad)/g
  },
  print: {
    media_query: 'print'
  }
};


class Screen {

  attachedKey(name) { return `${consts('KEY_SCREEN')}.${name}.attached`; }

  constructor() {
    this.subscribeScreen = this.subscribeScreen.bind(this);
    this.onScreen = this.onScreen.bind(this);
    this.offScreen = this.offScreen.bind(this);
    let data = _.extend({}, defaultScreens, model.get(consts('KEY_SCREEN')));
    if (data) { _.each(data, this.subscribeScreen); }
  }

  subscribeScreen(info, name) {
    if (info.user_agent &&
    !window.navigator.userAgent.match(_.toRegExp(info.user_agent))) {
      return this.offScreen(name);
    } else if (info.media_query) {
      if( !model.get(consts('KEY_IS_RESPONSIVE'))){
        return this.onScreen('desktop');
      } else if (rh.responsive.isSupported()) {
        return rh.responsive.attach(info.media_query, () => {
          return this.onScreen(name);
        }
        , () => {
          return this.offScreen(name);
        });
      } else if (name === model.get(consts('KEY_DEFAULT_SCREEN'))) {
        return this.onScreen(name);
      } else {
        return this.offScreen(name);
      }
    } else {
      return this.onScreen(name);
    }
  }

  onScreen(name) {
    let key = this.attachedKey(name);
    return model.publish(key, true);
  }
  
  offScreen(name) {
    let key = this.attachedKey(name);
    if (false !== model.get(key)) { return model.publish(key, false); }
  }
}

model.subscribe(consts('EVT_WIDGET_BEFORELOAD'), (function() {
  let screen = null;
  return () => screen != null ? screen : (screen = new Screen());
})()
);

model.publish(consts('KEY_SCREEN_NAMES'), ['desktop', 'tablet', 'phone']);
model.publish(consts('KEY_SCREEN'), defaultScreens);
model.publish(consts('KEY_DEFAULT_SCREEN'), 'phone');
