let { rh } = window;
let util = rh._;
let { $ } = rh;

class Global extends rh.Widget {

  constructor(opts) {
    super(opts);
    if (this.model == null) { this.model = rh.model; }
  }
}
    
window.rh.widgets.Global = Global;