let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

//Widget class for any custom behavior on dom node
var Widget = (function() {
  let _count = undefined;
  Widget = class Widget extends rh.Guard {
    static initClass() {

       //private static variable
      _count = 0;

      this.prototype.dataAttrs = ['repeat', 'init', 'stext', 'shtml',
        'controller', 'class', 'animate', 'css', 'attr', 'value', 'checked',
        'html', 'text', 'if', 'hidden', 'keydown', 'keyup', 'scroll',
        'change', 'toggle', 'toggleclass', 'method', 'trigger', 'click', 'load',
        'mouseover', 'mouseout', 'focus', 'blur',
        'swipeleft', 'swiperight', 'swipeup', 'swipedown', 'screenvar'];

      this.prototype.dataAttrMethods = (() => Widget.prototype.mapDataAttrMethods(Widget.prototype.dataAttrs))();

      //all list/data-reapeat items data-i attribute are support
      //this is the list of special list item attribute.
      //That means attributes like data-ihref, data-iid etc will
      // be supported without listing here.
      this.prototype.dataIAttrs = ['itext', 'ihtml', 'iclass', 'irepeat'];
      this.prototype.dataIAttrMethods = (() => Widget.prototype.mapDataAttrMethods(Widget.prototype.dataIAttrs))();

      this.prototype.supportedArgs = ['node', 'model', 'key', 'user_vars',
        'templateExpr', 'include'];

      this.prototype.resolveEventRawExpr = _.memoize(function(rawExpr) {
        let {expr, opts} = _.resolveExprOptions(rawExpr);
        expr = this.patchRawExpr(expr, opts);
        let exprFn = this._function('event, node', expr);
        let callback = _.safeExec(exprFn);
        callback = _.applyCallbackOptions(callback, opts);
        return {callback, opts};
      });

      this.prototype.resolveRawExprWithValue = _.memoize(function(rawExpr) {
        let keys = [];
        let {expr, opts} = _.resolveExprOptions(rawExpr);
        expr = this.patchRawExpr(expr, opts);
        let exprFn = this._evalFunction('', expr, keys);
        let callback = _.safeExec(exprFn);
        callback = _.applyCallbackOptions(callback, opts);
        return {callback, keys, opts};
      });

    //######### Heleper function to create functions in widget #################
      this.prototype.resolveExpression = _.memoize(function(expr) {
        let keys = [];
        return {
          expr: _.resolveModelKeys(_.resolveNamedVar(expr), keys),
          keys
        };
      });

      this.prototype._safeFunction = _.memoize(function(arg, expr) {
        let fn;
        try {
          fn = new Function(arg, expr);
        } catch (error) {
          fn = function() {};
          if (rh._debug) { rh._d('error', `Expression: ${expr}`, error.message); }
        }
        return fn;
      });

      this.prototype._eventCallBackData = {};

      this.prototype.resolveAttr = (function() {
        let cache = {};
        return function(attrsData) {
          let props = cache[attrsData];
          if (props == null) {
            props = _.resolveAttr(attrsData);
            cache[attrsData] = props;
          }
          return props;
        };
      })();

      /*
       * Toggle model variable on click
       * Example: data-toggle='showhide'
       *          data-toggle='showLeftBar:true'
       *          data-toggle='showLeftBar:true;showRightBar:false'
       */
      this.prototype._toggleData = {};


      /*
       * Example: data-load='test.js'
       *          data-load='test.js:key'
       */
      this.prototype._loadData = {};
    }

    toString() { return `${this.constructor.name}_${this._count}`; }

    mapDataAttrMethods(attrs) {
      return _.reduce(attrs, function(map, value) {
        map[`data-${value}`] = `data_${value}`;
        return map;
      }
      , {});
    }

    constructor(opts) {
      super();
      this.reRender = this.reRender.bind(this);
      _count += 1;
      this._count = _count;
      for (let key of Array.from(this.supportedArgs)) { if (opts[key]) { this[key] = opts[key]; } }
      if (this.templateExpr || this.include) { this.useTemplate = true; }
      this.parseOpts(opts);
      if (!this.node) { rh._d('error', 'constructor', `${this} does not have a node`); }
    }

    destruct() {
      this.resetContent();
      if (this._subscriptions) { for (let unsub of Array.from(this._subscriptions)) { unsub(); } }
      this._subscriptions = [];
      delete this.model;
      return delete this.controllers;
    }

    parseOpts(opts) {
      this.opts = opts;
      if (opts.arg) { this.key = opts.arg; }
      return (this.parsePipedArg)();
    }

    parsePipedArg() {
      let args = this.opts.pipedArgs;
      if (args != null ? args.shift : undefined) { //first piped argument is default Model
        return this.modelArgs = _.resolveNiceJSON(args.shift());
      }
    }

    get(key) {
      if (this.model == null) { this.model = new rh.Model(); }
      return this.model.get(key);
    }

    publish(key, value, opts) {
      if (this.model == null) { this.model = new rh.Model(); }
      return this.model.publish(key, value, opts);
    }

    subscribe(key, fn, opts) {
      if (key == null) { return; }
      if (this.model == null) { this.model = new rh.Model(); }
      let unsub = this.model.subscribe(key, fn, opts);
      if (this.model.isGlobal() || this.model.isGlobal(key)) { unsub = this.storeSubscribe(unsub); }
      return unsub;
    }

    subscribeOnly(key, fn, opts) {
      if (opts == null) { opts = {}; }
      opts['initDone'] = true;
      return this.subscribe(key, fn, opts);
    }

    storeSubscribe(unsub) {
      if (this._subscriptions == null) { this._subscriptions = []; }
      var newUnsub = () => {
        let index = this._subscriptions.indexOf(newUnsub);
        if ((index != null) && (index !== -1)) {
          this._subscriptions.splice(index, 1);
        }
        return unsub();
      };
      this._subscriptions.push(newUnsub);
      return newUnsub;
    }

    /*
     * data-if="@sidebar_open | screen: desktop"
     * data-if="@screen.desktop.attached === true && @sidebar_open"
     */
    patchScreenOptions(expr, screen) {
      let names = _.isString(screen) ? [screen]  : screen;
      let screenExpr = _.map(names, name => `@${consts('KEY_SCREEN')}.${name}.attached`).join(' || ');
      if (screenExpr) {
        return `${screenExpr} ? (${expr}) : null`;
      } else {
        return expr;
      }
    }

    patchDirOptions(expr, dir) {
      return `@${consts('KEY_DIR')} == '${dir}' ? (${expr}) : null`;
    }

    patchRawExprOptions(expr, opts) {
      if (opts.screen) { expr = this.patchScreenOptions(expr, opts.screen); }
      if (opts.dir != null) { expr = this.patchDirOptions(expr, opts.dir); }
      return expr;
    }

    patchRawExpr(expr, opts) {
      if (expr && _.isValidModelKey(expr)) { expr = `@${expr}`; }
      if (opts) { expr = this.patchRawExprOptions(expr, opts); }
      return expr;
    }

    subscribeExpr(rawExpr, fn, subs, opts) {
      if (rawExpr == null) { return; }
      let {callback, keys, expOpts} = this.resolveRawExprWithValue(rawExpr);
      let subsFn = () => {
        return fn.call(this, callback.call(this), expOpts);
      };

      for (let key of Array.from(keys)) {
        let unsub = this.subscribeOnly(key, subsFn, opts);
        if (subs) { subs.push(unsub); }
      }
      return subsFn();
    }

    resetContent() {
      if (this.children) { for (let child of Array.from(this.children)) { child.destruct(); } }
      if (this.htmlSubs) { for (let unsub of Array.from(this.htmlSubs)) { unsub(); } }
      this.children = [];
      return this.htmlSubs = [];
    }

    addChild(child) {
      if (this.children == null) { this.children = []; }
      return this.children.push(child);
    }

    linkModel(fromModel, fromKey, toModel, toKey, opts) {
      if (opts == null) { opts = {}; }
      let partial = (opts.partial != null) ? opts.partial : false;
      return this.storeSubscribe(fromModel.subscribe(fromKey, value => {
        return this.guard((() => toModel.publish(toKey, value, {sync: true})), this.toString());
      }
      , {partial})
      );
    }

    init(parent) {
      let initExpr;
      if (this.initDone) { return; }
      this.initDone = true;
      this.initParent(parent);
      (this.initModel)();

      if (initExpr = $.dataset(this.node, 'init')) {
        this.data_init(this.node, initExpr);
        $.dataset(this.node, 'init', null);
      }

      this.render();
      return this.subscribeOnly(this.opts.renderkey, this.reRender, {partial: false});
    }

    initParent(parent) {
      if (parent) { parent.addChild(this); }
      let parentModel = (parent != null ? parent.model : undefined) || rh.model;
      let input = __guard__($.dataset(this.node, 'input'), x => x.trim());
      let output = __guard__($.dataset(this.node, 'output'), x1 => x1.trim());

      if ((input === '.') || (output === '.')) {
        return this.model = parentModel;
      } else {
        let keys, opts;
        if (input || output || this.key) { if (this.model == null) { this.model = new rh.Model(); } }
        if (input) {
          ({keys, opts} = _.resolveInputKeys(input));
          _.each(keys, function(parentKey, key) {
            if (parentKey == null) { parentKey = key; }
            return this.linkModel(parentModel, parentKey, this.model, key, opts);
          }
          , this);
        }
        if (output) {
          ({keys, opts} = _.resolveInputKeys(output));
          return _.each(keys, function(parentKey, key) {
            if (parentKey == null) { parentKey = key; }
            return this.linkModel(this.model, key, parentModel, parentKey, opts);
          }
          , this);
        }
      }
    }

    initModel() {
      if (this.modelArgs) {
        _.each(this.modelArgs, function(value, key) {
          return this.publish(key, value);
        }
        , this);
        return delete this.modelArgs;
      }
    }

    initUI() {
      if (rh._debug) {
        let loadedWidgets = $.dataset(this.node, 'loaded');
        if (loadedWidgets) { loadedWidgets = `${loadedWidgets};${this}`; }
        $.dataset(this.node, 'loaded', loadedWidgets || this);
      } else {
        $.dataset(this.node, 'loaded', true);
      }

      if (this.templateExpr) { (this.subscribeTemplateExpr)(); }
      if (this.include) { (this.subscribeIncludePath)(); }
      if (this.tplNode == null) { this.tplNode = this.node; }
      return (this.resetContent)();
    }

    subscribeTemplateExpr() {
      let constructing = true;
      this.subscribeExpr(this.templateExpr, function(template) {
        this.tplNode = $.createElement('div', template).firstChild;
        if (!constructing) { return this.reRender(true); }
      });
      constructing = false;
      return this.templateExpr = undefined;
    }

    subscribeIncludePath() {
      _.require(this.include, template => this.setTemplate(template));
      return this.include = undefined;
    }

    setTemplate(template) {
      this.useTemplate = true;
      this.tplNode = $.createElement('div', template).firstChild;
      return this.reRender(true);
    }

    reRender(render) { if ((render != null) && this.tplNode) { return this.render(); } }

    preRender() {
      let oldNode;
      if (this.useTemplate) {
        oldNode = this.node;
        this.node = this.tplNode.cloneNode(true);
      }
      return oldNode;
    }

    postRender(oldNode) {
      if (oldNode && oldNode.parentNode) {
        return oldNode.parentNode.replaceChild(this.node, oldNode);
      }
    }

    alterNodeContent() {}

    render() {
      if (rh._test) { rh.model.publish(`test.${this}.render.begin`, _.time()); }
      this.initUI();
      let oldNode = this.preRender();
      this.nodeHolder = new rh.NodeHolder([this.node]);
      (this.alterNodeContent)();
      this.resolveDataAttrs(this.node);
      _.loadDataHandlers(this.node, this);
      this.postRender(oldNode);
      if (rh._test) { return rh.model.publish(`test.${this}.render.end`, _.time()); }
    }

    isVisible() { return this.nodeHolder.isVisible(); }

    show() { return this.nodeHolder.show(); }

    hide() { return this.nodeHolder.hide(); }

    toggle() { if (this.isVisible()) { return this.hide(); } else { return this.show(); } }

    isWidgetNode(node) { return $.dataset(node, 'rhwidget'); }

    isDescendent(node) {
      let nestedWidget;
      let child = node;
      while (true) {
        let parent = child.parentNode;
        if (!parent) { break; }
        if (this.isWidgetNode(child)) {
          nestedWidget = parent;
          break;
        }
        if (this.node === parent) { break; }
        child = parent;
      }
      return (nestedWidget != null);
    }

    eachChild(selector, fn) {
      return $.eachChild(this.node, selector, function(node) {
        if (!this.isDescendent(node)) { return fn.call(this, node); }
      }
      , this);
    }

    eachDataNode(dataAttr, fn) {
      return $.eachDataNode(this.node, dataAttr, function(node, value) {
        if (!this.isDescendent(node)) { return fn.call(this, node, value); }
      }
      , this);
    }

    traverseNode(node, pre, post) {
      return $.traverseNode(node, pre, post, function(child) {
        return !this.isDescendent(child);
      }
      , this);
    }

    resolveDataAttrs(pnode) {
      return this.traverseNode(pnode, function(node) {
        let repeatVal;
        if (_.isString(repeatVal = $.dataset(node, 'repeat'))) {
          this.data_repeat(node, repeatVal);
          return false;
        } else {
          $.eachAttributes(node, function(name, value) {
            let fnName = this.dataAttrMethods[name];
            if (fnName && value) { return this[fnName].call(this, node, value); }
          }
          , this);
          return true;
        }
      });
    }

    resolveRepeatExpr(rawExpr) {
      let values = _.resolvePipedExpression(rawExpr);
      let opts = values[1] && _.resolveNiceJSON(values[1]);
      let data = _.resolveLoopExpr(values[0]);
      if (opts != null ? opts.filter : undefined) {
        data['filter'] = this._evalFunction('item, index', opts.filter);
      }
      data['step'] = (opts != null ? opts.step : undefined) || 1;
      return data;
    }

    /*
     * varName: Ex: #{@data.title} means item.data.title
     */
    resolveRepeatVar(expr, item, index, cache, node) {
      return cache[expr] = cache[expr] || (() => { switch (expr) {
        case '@index': return index;
        case '@size': return item.length;
        case 'this': return item;
        default:
          if (_.isValidModelKey(expr)) {
            return _.get(item, expr);
          } else {
            return this.subscribeIDataExpr(node, expr, item, index);
          }
      } })();
    }

    resolveEnclosedVar(value, item, index, itemCache, node) {
      return _.resolveEnclosedVar(value, function(varName) {
        return this.resolveRepeatVar(varName, item, index, itemCache, node);
      }
      , this);
    }

    updateEncloseVar(name, value, item, index, itemCache, node) {
      let newValue = this.resolveEnclosedVar(value, item, index, itemCache, node);
      if (newValue === '') {
        $.removeAttribute(node, name);
      } else if (newValue !== value) {
        $.setAttribute(node, name, newValue);
      }
      return newValue;
    }

    updateWidgetEncloseVar(item, index, itemCache, node) {
      return _.each(['rhwidget', 'input', 'output', 'init'], function(name) {
        let value;
        if (value = $.dataset(node, name)) {
          return this.updateEncloseVar(`data-${name}`, value, item, index, itemCache, node);
        }
      }
      , this);
    }

    isRepeat(node) {
      return $.dataset(node, 'repeat') || $.dataset(node, 'irepeat');
    }

    resolveNestedRepeat(node, item, index, itemCache) {
      return _.each(['repeat', 'irepeat'], function(name) {
        let value;
        if (value = $.dataset(node, name)) {
          value = this.updateEncloseVar(`data-${name}`, value,
            item, index, itemCache, node);
          if (value !== '') { return (typeof this[`data_${name}`] === 'function' ? this[`data_${name}`](node, value, item, index) : undefined); }
        }
      }
      , this);
    }

    resolveItemIndex(pnode, item, index) {
      if (!pnode.children) { return; }
      let itemCache = {};
      return $.traverseNode(pnode, node => {
        if ((node !== pnode) && $.dataset(node, 'rhwidget')) {
          this.updateWidgetEncloseVar(item, index, itemCache, node);
          return false;
        }

        if (this.isRepeat(node)) {
          this.resolveNestedRepeat(node, item, index, itemCache);
          return false;
        }

        $.eachAttributes(node, function(name, value, attrsInfo) {
          if (_.isString(value)) {
            let fnName;
            if (0 === name.search('data-')) {
              value = this.updateEncloseVar(name, value,
                item, index, itemCache, node);
            }
            if (value === '') { return; }

            if (fnName = this.dataIAttrMethods[name]) {
              if (this[fnName].call(this, node, value, item, index, attrsInfo)) {
                return $.removeAttribute(node, name);
              }
            } else if (0 === name.search('data-i-')) {
              this.data_iHandler(node, value, item, index, name.substring(7));
              return $.removeAttribute(node, name);
            }
          }
        }
        , this);
        return true;
      });
    }

    guard(fn, guardName) {
      if (guardName == null) { guardName = 'ui'; }
      return super.guard(fn, guardName);
    }

    data_repeat(node, rawExpr) {
      $.dataset(node, 'repeat', null);
      node.removeAttribute('data-repeat');
      let opts = this.resolveRepeatExpr(rawExpr);

      let nodeHolder = new rh.NodeHolder([node]);
      this.subscribeDataExpr(opts.expr, result => {
        //TODO usub old subs using stack of html subs
        return this._repeatNodes(nodeHolder, result, opts, node);
      }
      , {partial: false});
      return true;
    }

    //if statement for data-repeat like structure
    resolve_rif(node, item, index) {
      let callback, cloneNode, rawExpr;
      if (rawExpr = $.dataset(node, 'rif')) {
        callback = this._evalFunction('item, index', rawExpr);
      }

      if (!callback || callback.call(this, item, index)) {
        cloneNode = node.cloneNode(false);
        $.dataset(cloneNode, 'rif', null);
        for (let child of Array.from(node.childNodes)) {
          let cloneChild = this.resolve_rif(child, item, index);
          if (cloneChild) { cloneNode.appendChild(cloneChild); }
        }
      }
      return cloneNode;
    }

    _function(arg, expr, keys) {
      let data = this.resolveExpression(expr);
      if (keys) { for (let key of Array.from(data.keys)) { keys.push(key); } }
      return this._safeFunction(arg, data.expr);
    }

    _evalFunction(arg, expr, keys) {
      return this._function(arg, `return ${expr};`, keys);
    }

    //########## list or repeat items data attributes handling ############
    _setLoopVar(opts, item, index) {
      let oldValue = {};
      if (opts.item) {
        oldValue['item'] = this.user_vars[opts.item];
        this.user_vars[opts.item] = item;
      }
      if (opts.index) {
        oldValue['index'] = this.user_vars[opts.index];
        this.user_vars[opts.index] = index;
      }
      return oldValue;
    }

    _repeatNodes(nodeHolder, result, opts, tmplNode) {
      let cloneNode;
      if (result == null) { result = []; }
      if (this.user_vars == null) { this.user_vars = {}; }
      let newNodes = [];
      let {filter, step} = opts;
      for (let step1 = step, asc = step1 > 0, index = asc ? 0 : result.length - 1; asc ? index < result.length : index >= 0; index += step1) {
        let item = result[index];
        let oldValue = this._setLoopVar(opts, item, index);
        if (!filter || filter.call(this, item, index)) {
          if (cloneNode = this.resolve_rif(tmplNode, item, index)) {
            newNodes.push(cloneNode);
            this.resolveItemIndex(cloneNode, item, index);
            this.resolveDataAttrs(cloneNode);
          }
        }
        this._setLoopVar(opts, oldValue.item, oldValue.index);
      }


      if (newNodes.length === 0) {
        let tempNode = tmplNode.cloneNode(false);
        $.addClass(tempNode, 'rh-hide');
        newNodes.push(tempNode);
      }

      return nodeHolder.updateNodes(newNodes);
    }

    data_irepeat(node, rawExpr, item, index, attrsInfo) {
      $.dataset(node, 'irepeat', null);
      let opts = this.resolveRepeatExpr(rawExpr);
      let nodeHolder = new rh.NodeHolder([node]);
      let result = this.subscribeIDataExpr(node, opts.expr, item, index);
      this._repeatNodes(nodeHolder, result, opts, node);
      return true;
    }

    /*
     * helper method for r(repeat) attributes
     */
    subscribeIDataExpr(node, rawExpr, item, index, attrsInfo) {
      let exprFn = this._evalFunction('item, index, node', rawExpr);
      try {
        return exprFn.call(this, item, index, node);
      } catch (error) {
        if (rh._debug) { return rh._d('error', `iExpression: ${rawExpr}`, error.message); }
      }
    }

    /*
     * get the key value and fills its value as text content
     * Example: <a data-itext="item.title">temp value</a>
     *          <div data-itext="@key">temp value</div>
     */
    data_itext(node, rawExpr, item, index, attrsInfo) {
      $.textContent(node, this.subscribeIDataExpr(node, rawExpr, item, index));
      return true;
    }

    /*
     * get the key value and fills its value as HTML content
     * Example: <a data-ihtml="item.data">temp value</a>
     *          <div data-ihtml="@key">temp value</div>
     */
    data_ihtml(node, rawExpr, item, index, attrsInfo) {
      node.innerHTML = this.subscribeIDataExpr(node, rawExpr, item, index);
      return true;
    }

    /*
     * get the key value and fills its value as text content
     * Example: <a data-iclass="item.data?'enabled':'disabled'">temp value</a>
     *          <div data-iclass="@key">temp value</div>
     */
    data_iclass(node, rawExpr, item, index, attrsInfo) {
      let className = this.subscribeIDataExpr(node, rawExpr, item, index);
      if (className) { $.addClass(node, className); }
      return true;
    }

    /*
     * get the key value and fills its value as text content
     * Example: <a data-ihref="item.url">temp value</a>
     *          <div data-iid="item.id">temp value</div>
     */
    data_iHandler(node, rawExpr, item, index, attrName) {
      let attrValue = this.subscribeIDataExpr(node, rawExpr, item, index);
      if (attrValue) { $.setAttribute(node, attrName, attrValue); }
      return true;
    }

    //################ Static data attributes handling ##########################
    /* get the key value at the time of rendering
     * and fills its value as html content
     * Example: <a data-shtml="key">temp value</a>
     *          <div data-shtml="key">temp value</div>
     */
    data_shtml(node, key) {
      $.removeAttribute(node, 'data-shtml');
      return node.innerHTML = this.get(key);
    }

    /*
     * get the key value and fills its value as text content
     * Example: <a data-stext="key">temp value</a>
     *          <div data-stext="key">temp value</div>
     */
    data_stext(node, key) {
      $.removeAttribute(node, 'data-stext');
      return $.textContent(node, this.get(key) || '');
    }

    //################ Generic data attributes handling ##########################
    /*
     * evaluates expression value to init
     * Example: data-init="@key(true)"
     *          data-init="rh._.loadScript('p.toc')"
     */
    data_init(node, rawExpr) {
      let resolvedData = _.resolveExprOptions(rawExpr);
      let callback = this._function('node', resolvedData.expr);
      callback = _.applyCallbackOptions(callback, resolvedData.opts);
      return callback.call(this, node);
    }

    /*
     * helper method for data methods having expression like data-if
     */
    subscribeDataExpr(rawExpr, handler, opts) {
      return this.subscribeExpr(rawExpr, handler, this.htmlSubs, opts);
    }
    _data_event_callback(rawExpr) {
      let data = Widget.prototype._eventCallBackData[rawExpr];
      if (data == null) {
        data = {};
        let value = _.resolvePipedExpression(rawExpr);
        data.callback = this._function('event, node', value[0]);
        if (value[1]) { data.opts = _.resolveNiceJSON(value[1]); }
        Widget.prototype._eventCallBackData[rawExpr] = data;
      }
      return data;
    }

    /*
     * subscribes to keys and evaluates expression value to show or hide
     * Example: data-if="@key"
     *          data-if="!@key&&@key2"
     *          data-if='this.get("key", "value")'
     *          data-if="@key==value"
     *          data-if="@key!==value"
     */
    data_if(node, rawExpr) {
      let nodeHolder = new rh.NodeHolder([node]);
      return this.subscribeDataExpr(rawExpr, function(result) {
        if (result) { return nodeHolder.show(); } else { return nodeHolder.hide(); }
      });
    }

    data_hidden(node, rawExpr) {
      let nodeHolder = new rh.NodeHolder([node]);
      return this.subscribeDataExpr(rawExpr, result => nodeHolder.accessible(!result));
    }

    /*
     * subscribes to a key and fills its value as html content
     * Example: <a data-html="@key">temp value</a>
     *          <div data-html="@key['url']">temp value</div>
     */
    data_html(node, rawExpr) {
      return this.subscribeDataExpr(rawExpr, function(result) {
        if (result == null) { result = ''; }
        node.innerHTML = result;
        //TODO unsub old subscribes
        return $.eachChildNode(node, child => this.resolveDataAttrs(child));
      });
    }

    /*
     * subscribes to a key and fills its value as text content
     * Example: <a data-text="@key">temp value</a>
     *          <div data-text="@key['title']">temp value</div>
     */
    data_text(node, rawExpr) {
      return this.subscribeDataExpr(rawExpr, function(result) {
        if (result == null) { result = ''; }
        return $.textContent(node, result);
      });
    }
    /*
     * provide expression to update the class attribute
     * Example: data-class="selected: #{@index} == @.dataidx"
     * data-class="selected: @key1; bold: @key2"
     */
    data_class(node, attrsData) {
      return _.each(this.resolveAttr(attrsData), function(rawExpr, className) {
        let nodeHolder = new rh.NodeHolder([node]);
        return this.subscribeDataExpr(rawExpr, function(result) {
          let addRemoveClass = result ? [className] : [];
          return nodeHolder.updateClass(addRemoveClass);
        });
      }
      , this);
    }

    /*
     * To update any html tag attribute.
     * Example: <a data-attr="href:link_key">Google</a>
     *          <button data-attr="disabled:key">temp value</button>
     */
    data_attr(node, attrsData) {
      return _.each(this.resolveAttr(attrsData), function(rawExpr, attr_name) {
        return this.subscribeDataExpr(rawExpr, function(result) {
          if (result != null) {
            return $.setAttribute(node, attr_name, result);
          } else if ($.hasAttribute(node, attr_name)) {
            return $.removeAttribute(node, attr_name);
          }
        });
      }
      , this);
    }

    /*
     * To update style attribute of HTML node.
     * Example:
     * <span style="visible: true;" data-css="visible: @key"> some text </span>
     * <li style="color: blue; display: block;" data-css="color:
     * @.selected_color; display: @.dataidx > 10 ? 'none' : 'block'"></li>
     */
    data_css(node, attrsData) {
      return _.each(this.resolveAttr(attrsData), function(rawExpr, styleName) {
        return this.subscribeDataExpr(rawExpr, (result = null) => // null to force set css
          $.css(node, styleName, result)
        );
      }
      , this);
    }

    /*
     * works like data-if but sets the states checked
     * Example:
     * <input type="radio" name="group1" value="Print" data-checked="key" />
     * <input type="radio" name="group1" value="Online" data-checked="key" />
     */
    data_checked(node, key) {
      if (_.isValidModelConstKey(key)) { key = consts(key); }
      let type = node.getAttribute('type');
      if ((type === 'checkbox') || (type === 'radio')) {
        let nodeValue;
        if ($.getAttribute(node, 'checked')) {
          this.guard(function() { return this.publish(key, node.getAttribute('value', {sync: true})); });
        }

        node.onclick = () => {
          nodeValue = node.getAttribute('value');
          let value =
            nodeValue === null ?
              node.checked
            : node.checked ?
              nodeValue
            :
              undefined;
          return this.guard(function() { return this.publish(key, value, {sync: true}); });
        };
        return this.htmlSubs.push(this.subscribe(key, function(value) {
          nodeValue = node.getAttribute('value');
          if (nodeValue != null) {
            return node.checked = value === nodeValue;
          } else {
            return node.checked = value === true;
          }
        })
        );
      }
    }

    /*
     * subscribes to a key and fills its value as html content
     * Example:
     * <input type="text" data-value="key" />
     * <input type="text" value="Online" data-value="key" />
     */
    data_value(node, key) {
      if (_.isValidModelConstKey(key)) { key = consts(key); }
      let nodeGuard = Math.random();
      if (node.value) { this.guard((function() { return this.publish(key, node.value, {sync: true}); }), nodeGuard); }

      node.onchange = () => {
        return this.guard((function() { return this.guard(function() { return this.publish(key, node.value, {sync: true}); });
         }), nodeGuard);
      };

      return this.htmlSubs.push(this.subscribe(key, value => {
        return this.guard((() => node.value = value), nodeGuard);
      })
      );
    }


    _register_event_with_rawExpr(name, node, rawExpr) {
      let {callback} = this.resolveEventRawExpr(rawExpr);
      _.addEventListener(node, name, e => callback.call(this, e, e.currentTarget));
      return callback;
    }

    /*
     * Example: data-click='@key("value")'
     *          data-click='this.publish("key", "value")'
     *          data-click='@key("value"); event.preventDefault();'
     */
    data_click(node, rawExpr) {
      return this._register_event_with_rawExpr('click', node, rawExpr);
    }

    /*
     * Example: data-mouseover='@key("value")'
     *          data-mouseover='this.publish("key", "value")'
     *          data-mouseover='@key("value"); event.preventDefault();'
     */
    data_mouseover(node, rawExpr) {
      return this._register_event_with_rawExpr('mouseover', node, rawExpr);
    }

    data_mouseout(node, rawExpr) {
      return this._register_event_with_rawExpr('mouseout', node, rawExpr);
    }

    data_focus(node, rawExpr) {
      return this._register_event_with_rawExpr('focus', node, rawExpr);
    }

    data_blur(node, rawExpr) {
      return this._register_event_with_rawExpr('blur', node, rawExpr);
    }

    /*
     * trigger
     * Example: data-trigger='.l.go_to_top'
     *          data-trigger='EVT_SEARCH_PAGE'
     */
    data_trigger(node, key) {
      if (_.isValidModelConstKey(key)) { key = consts(key); }
      return _.addEventListener(node, 'click', () => this.publish(key, null));
    }

    /*
     * call member or global method on click
     * advantage is you will get event as argument
     * Example: data-method='handleSave' => data-click='this.handleSave(event)'
     *          data-method='handleCancel'
     */
    data_method(node, method) {
      return _.addEventListener(node, 'click', event => {
        if (!event.defaultPrevented) { return (this[method] || window[method])(event); }
      });
    }
    data_toggle(node, rawArgs) {
      let opts;
      let keys = [];
      let data = Widget.prototype._toggleData[rawArgs];
      if (data == null) {
        let pipedArgs = _.resolvePipedExpression(rawArgs);
        let config = pipedArgs.shift() || '';
        config = _.explodeAndMap(config, ';', ':', {trim: true});
        if (pipedArgs[0]) { opts = _.resolveNiceJSON(pipedArgs[0]); }
        data = {keyValues: config, opts};
        Widget.prototype._toggleData[rawArgs] = data;
      }

      _.each(data.keyValues, function(value, key) {
        keys.push(key);
        if (value != null) {
          return this.guard(function() { return this.publish(key, value === 'true', {sync: true}); });
        }
      }
      , this);

      let callback = key => this.guard(function() { return this.publish(key, !this.get(key), {sync: true}); });
      if (data.opts) { callback = _.applyCallbackOptions(callback, data.opts); }

      return _.addEventListener(node, 'click', event => _.each(keys, function(key) { if (!event.defaultPrevented) { return callback(key); } }));
    }

    /*
     * Toggle model variable on click
     * Example: data-toggleclass='rh-hide'
     *          data-toggleclass='open'
     *          <div class="open" data-toggleclass='open,closed'>
     */
    data_toggleclass(node, classNames) {
      let newClasses = _.splitAndTrim(classNames, ',');
      return _.addEventListener(node, 'click', function(event) {
        if (!event.defaultPrevented) {
          node = event.currentTarget;
          return _.each(newClasses, function(className) {
            if ($.hasClass(node, className)) {
              return $.removeClass(node, className);
            } else {
              return $.addClass(node, className);
            }
          });
        }
      });
    }

    /*
     * Example: data-change='@key("value")'
     *          data-change='this.publish("key", "value")'
     */
    data_change(node, rawExpr) {
      let data = this._data_event_callback(rawExpr);
      let callback = _.applyCallbackOptions(data.callback, data.opts);
      return node.onchange = event => callback.call(this, event, event.currentTarget);
    }

    /*
     * Example: data-keydown='@text(node.value); | keyCode: 13'
     *          data-keydown='event.keyCode == 13 && @text(node.value)'
     *          data-keydown='this.publish("key", "myvalue");'
     *          data-keydownoptions='debounce:300'
     */
    data_keydown(node, rawExpr) {
      let data = this._data_event_callback(rawExpr);
      let callback = _.applyCallbackOptions(data.callback, data.opts);
      let keyCode = data.opts && data.opts.keyCode;

      return node.onkeydown = event => {
        if (!keyCode || (keyCode === event.keyCode)) {
          return callback.call(this, event, event.currentTarget);
        }
      };
    }

    /*
     * Example: data-keyup='if(key == 13)@text(node.value);'
     *          data-keyup='@text(node.value) | keyCode: 13'
     *          data-keyup='this.publish("key", "myvalue") | debounce:300'
     */
    data_keyup(node, rawExpr) {
      let data = this._data_event_callback(rawExpr);
      let callback = _.applyCallbackOptions(data.callback, data.opts);
      let keyCode = data.opts && data.opts.keyCode;

      return node.onkeyup = event => {
        if (!keyCode || (keyCode === event.keyCode)) {
          return callback.call(this, event, event.currentTarget);
        }
      };
    }

    /*
     * Example: data-scroll='@text(node.value) | debounce:300'
     *          data-scroll='this.publish("key", "myvalue")'
     */
    data_scroll(node, rawExpr) {
      let data = this._data_event_callback(rawExpr);
      let { opts } = data;
      let delta = (opts && opts.delta) || 100;
      let callback = event => {
        let rect = node.getBoundingClientRect();
        if (node.scrollTop > (node.scrollHeight - (rect.height + delta))) {
          return data.callback.call(this, event, node);
        }
      };

      if (opts) { callback = _.applyCallbackOptions(callback, opts); }
      return _.addEventListener(node, 'scroll', callback);
    }
    data_load(node, value) {
      let pair = value.split(':');
      let jsPath = pair[0];
      let key = pair[1];
      if (!Widget.prototype._loadData[jsPath]) {
        return _.addEventListener(node, 'click', event => {
          if (!Widget.prototype._loadData[jsPath]) {
            Widget.prototype._loadData[jsPath] = true;
            if (key) {
              $.addClass(node, 'loading');
              var unsub = this.subscribeOnly(key, function() {
                $.removeClass(node, 'loading');
                return unsub();
              });
            }
            return _.loadScript(jsPath);
          }
        });
      }
    }

    data_controller(node, value) {
      if (this.user_vars == null) { this.user_vars = {}; }
      for (let data of Array.from(_.splitAndTrim(value, ';'))) {
        var ctrlClass, opts;
        let pair = _.resolvePipedExpression(data);
        if (pair[1]) { opts = _.resolveNiceJSON(pair[1]); }
        pair = _.splitAndTrim(pair[0], ':');
        if (pair.length === 0) { pair = _.splitAndTrim(pair[0], ' as '); }
        if (pair[0] != null) { ctrlClass = rh.controller(pair[0]); }
        let ctrlName = pair[1];
        if (ctrlClass && !this.user_vars[ctrlName]) {
          let controller = new ctrlClass(this, opts);
          if (ctrlName) { this.user_vars[ctrlName] = controller; }
        } else if (rh._debug && !ctrlClass) {
          rh._d('error', `Controller ${ctrlClass} not found`);
        }
      }
    }

    data_screenvar(node, value) {
      let sVars = _.splitAndTrim(value, ',');
      let current_screen = _.findIndex(this.get(consts('KEY_SCREEN')), (v, k) => v.attached);
      let cache = {};
      cache[current_screen] = {};

      let screenKeys = this.get(consts('KEY_SCREEN'));
      return _.each((_.keys(screenKeys)), function(key) {
        return this.subscribeOnly(`${consts('KEY_SCREEN')}.${key}.attached`, attached => {
          let name;
          if (!attached) { return; }
          _.each(sVars, function(sVar) {
            cache[current_screen][sVar] = this.get(sVar);
            if (cache[key] != null) { return this.publish(sVar, cache[key][sVar]); }
          }
          , this);
          return cache[name = (current_screen = key)] != null ? cache[name] : (cache[name] = {});
      });
      }
      , this);
    }
  };
  Widget.initClass();
  return Widget;
})();

  //######################### Utility methods #########################

rh.widgets = {};
rh.Widget = Widget;
rh.widgets.Basic = Widget;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
