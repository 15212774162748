const { rh } = window;
const { _ } = rh;
const { $ } = rh;
const { consts } = rh;
const { model } = rh;
const { http } = rh;
const formdata = rh.formData;

class RoboHelpServer {

  toString() { return 'RoboHelpServer'; }

  constructor() {}

  setResultsStringHTML(results_no, searchStr){
    var msg = gResultsFoundString;
    msg = msg.replace("%1", results_no);
    msg = msg.replace("%2", "\'" + searchStr + "\'");
    this.displayMsg(msg)
  }

  publishTopicUrls(topics, syns, searchText){
    let id
    const resultsParams = '?' + _.mapToEncodedString(_.extend({ rhhlterm: searchText },
      { rhsyns: syns })
    )
    _.each(topics, topic => { 
      id = `${topic.nTopicId}_${topic.nIndex}`
      rh.model.publish(`.p.searchsummary${id}`, topic.strSummary)
      rh.model.publish(`.p.searchurl${id}`, `${topic.strUrl}${resultsParams}`)
      topic.strUrl = `.p.searchurl${id}`
      topic.strSummary = `.p.searchsummary${id}`
    })
}
  
  displayMsg(msg){
    var spanNode = document.getElementById(gSearchMsgId);
    if(spanNode != null && spanNode != 'undefined')
      spanNode.innerHTML = msg;
  }

  area() {
    return _.urlParam('area', _.extractParamString());
  }

  type() {
    return _.urlParam('type', _.extractParamString());
  }

  project() {
    return _.urlParam('project', _.extractParamString());
  }

  logTopicView(topic) {
    return model.subscribe(consts('EVT_PROJECT_LOADED'), () => {
      const baseUrl = model.get(consts('KEY_PUBLISH_BASE_URL'));
      const parentPath = _.parentPath(_.filePath(_.getRootUrl()));
      const tpcUrl = _.isRelativeUrl(topic) ? parentPath + topic : topic;
      if (baseUrl && !_.isEmptyString(baseUrl)) {
        const hashString = _.mapToEncodedString(_.extend(consts('RHS_LOG_TOPIC_VIEW'),
          {area: this.area(), tpc: _.filePath(tpcUrl)})
        );
        return http.get(`${baseUrl}?${hashString}`);
      }
    });
  }

  preSearch() {
    let hashString;
    const searchText = model.get(consts('KEY_SEARCH_TERM'));
    if (searchText && !_.isEmptyString(searchText)) {
      hashString = _.mapToEncodedString(_.extend(consts('RHS_DO_SEARCH'), _.addPathNameKey({
        area: this.area(), type: this.type(), project: this.project(), quesn: searchText,
        oldquesn: '', quesnsyn: ''
      })
      )
      );

      model.publish(consts('KEY_SEARCHED_TERM'), searchText);
      model.publish(consts('EVT_SEARCH_IN_PROGRESS'), true);
      model.publish(consts('KEY_SEARCH_PROGRESS'), 0);
    }

    return {searchText, hashString};
  }

  postSearch(searchText, resultsText) {
    const searchResults = JSON.parse(resultsText);
    if (searchResults && searchResults.clientIndex) {
      const hashString = _.mapToEncodedString(_.addPathNameKey({area: this.area(), type: this.type(),
      project: this.project(), quesn: searchText, cmd: 'clientindex'}));
      model.subscribeOnce(consts('KEY_SEARCH_RESULTS'), function(data) {
        const baseUrl = model.get(consts('KEY_PUBLISH_BASE_URL'));
        return http.post(`${baseUrl}?${hashString}`, JSON.stringify(data),
        {'Content-type': 'application/json'})
        .error(function() {
          let result;
          return result = false;}).success(function() {
          let result;
          return result = true;
        });
      }
      , {initDone: true});

    }
    model.publish(consts('EVT_SEARCH_IN_PROGRESS'), false);
    model.publish(consts('KEY_SEARCH_PROGRESS'), null);

    if (searchResults) {
      const searchTopics = searchResults.topics;
      if (searchTopics && (searchTopics.length > 0))
      {
        this.publishTopicUrls(searchTopics, searchResults.syns, searchText)
        this.setResultsStringHTML(searchTopics.length,
        window._textToHtml_nonbsp(searchText));
      }



      model.publish(consts('KEY_SEARCH_RESULTS'), searchTopics);
    }

    if (!searchResults || !(searchResults.topics != null ? searchResults.topics.length : undefined)) {
      return this.displayMsg(window.gsNoTopics);
    }
  }

  doSearch() {
    let result = model.get(consts('KEY_PUBLISH_MODE'));
    const baseUrl = model.get(consts('KEY_PUBLISH_BASE_URL'));
    if (baseUrl && !_.isEmptyString(baseUrl)) {
      const {searchText, hashString} = this.preSearch();

      http.get(`${baseUrl}?${hashString}`)
      .error(() => result = false).success(resultsText => {
        this.postSearch(searchText, resultsText);
        return result = true;
      });
    }
    return result;
  }
}

rh.rhs = new RoboHelpServer();
