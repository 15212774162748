if (window.rh == null) { window.rh = {}; }
const { rh } = window;
if (rh._ == null) { rh._ = {}; }
rh.util = rh._;
const { _ } = rh;

const nativeForEach   = Array.prototype.forEach;
const nativeKeys      = Object.keys;
const { hasOwnProperty }  = Object.prototype;

_.time = () => (new Date()).getTime();

_.delay = function(fn, wait) {
  const args = []; let i = 1;
  while (++i < arguments.length) { args.push(arguments[i]); }
  return setTimeout(() => fn.apply(null, args)
  , wait);
};

_.defer = function(fn) {
  const args = []; let i = 0;
  while (++i < arguments.length) { args.push(arguments[i]); }
  return this.delay.apply(this, [fn, 1].concat(args));
};

_.debounce = function(fn, threshold, execAsap) {
  let timeout = null;
  return function() {
    const args = [];
    for (let arg of Array.from(arguments)) { args.push(arg); }
    const obj = this;
    const delayed = function() {
      if (!execAsap) { fn.apply(obj, args); }
      return timeout = null;
    };
    if (timeout) {
      clearTimeout(timeout);
    } else if (execAsap) {
      fn.apply(obj, args);
    }
    return timeout = setTimeout(delayed, threshold || 100);
  };
};

_.throttle = function(fn, threshold) {
  let timeout = null;
  let fnExecuted = false;
  return function() {
    const args = [];
    for (let arg of Array.from(arguments)) { args.push(arg); }
    const obj = this;
    const delayed = function() {
      if (!fnExecuted) { fn.apply(obj, args); }
      return timeout = null;
    };
    if (timeout) {
      clearTimeout(timeout);
      fnExecuted = false;
    } else {
      fn.apply(obj, args);
      fnExecuted = true;
    }

    return timeout = setTimeout(delayed, threshold || 100);
  };
};

_.timeout = (fn, wait) =>
  function() {
    const args = [];
    for (let arg of Array.from(arguments)) { args.push(arg); }
    const obj = this;
    const delayed = () => fn.apply(obj, args);
    return setTimeout(delayed, wait);
  }
;

_.toggleTimeout = (fn, wait, toggle) =>
  function() {
    const args = [];
    for (let arg of Array.from(arguments)) { args.push(arg); }
    const obj = this;
    const delayed = () => fn.apply(obj, args);
    if (toggle) {
      if (rh._debug) { args.push(_.stackTrace()); }
      setTimeout(delayed, wait);
    } else {
      delayed();
    }
    return toggle = !toggle;
  }
;

// Object methods

_.has = (obj, key) => (obj != null) && hasOwnProperty.call(obj, key);

_.keys = function(obj) {
  const keys = [];
  if (!_.isObject(obj)) { return keys; }
  if (nativeKeys) { return nativeKeys(obj); }
  for (let key in obj) { if (_.has(obj, key)) { keys.push(key); } }
  return keys;
};

//Iterators

_.any = function(obj, fn, context) {
  if (context == null) { context = this; }
  if (obj == null) { return false; }
  const keys = (obj.length !== +obj.length) && _.keys(obj);
  const { length } = (keys || obj);
  let index = 0;
  while (true) {
    if (index >= length) { break; }
    const key = keys ? keys[index] : index;
    if (fn.call(context, obj[key], key, obj)) { return true; }
    index++;
  }
  return false;
};

_.each = function(obj, fn, context) {
  let value;
  if (context == null) { context = this; }
  if (obj == null) { return; }
  if (nativeForEach === obj.forEach) {
    obj.forEach(fn, context);
  } else if (obj.length === +obj.length) {
    for (let index = 0; index < obj.length; index++) { value = obj[index]; fn.call(context, value, index, obj); }
  } else {
    for (let key in obj) { value = obj[key]; fn.call(context, value, key, obj); }
  }
  return obj;
};

_.map = function(obj, fn, context) {
  if (context == null) { context = this; }
  const result = [];
  _.each(obj, (value, key, obj) => result.push(fn.call(context, value, key, obj)));
  return result;
};

_.reduce = function(obj, fn, initial, context) {
  if (context == null) { context = this; }
  _.each(obj, (value, key) => initial = fn.call(context, initial, value, key));
  return initial;
};

_.find = function(obj, fn, context) {
  if (context == null) { context = this; }
  let result = undefined;
  _.any(obj, function(value, key, obj) {
    if (fn.call(context, value, key, obj)) {
      result = value;
      return true;
    }
  });
  return result;
};

_.findIndex = function(obj, fn, context) {
  if (context == null) { context = this; }
  let result = -1;
  _.any(obj, function(value, key, obj) {
    if (fn.call(context, value, key, obj)) {
      result = key;
      return true;
    }
  });
  return result;
};

_.findParentNode = function(node, rootNode, fn, context) {
  if (rootNode == null) { rootNode = document; }
  if (context == null) { context = this; }
  let result = null;
  while (true) {
    if (!node || (node === rootNode)) { break; }
    if (fn.call(context, node)) {
      result = node;
      break;
    }
    node = node.parentNode;
  }
  return result;
};

_.filter = function(obj, fn, context) {
  if (context == null) { context = this; }
  const result = [];
  _.each(obj, function(value, key, obj) {
    if (fn.call(context, value, key, obj)) { return result.push(value); }
  });
  return result;
};

_.flatten = obj =>
  _.reduce(obj, (result, elem) => result.concat(elem)
  , [])
;

_.unique = function(obj, fn, context) {
  if (context == null) { context = this; }
  if (fn) { obj = _.map(obj, fn, context); }
  return _.filter(obj, (value, index) => obj.indexOf(value) === index);
};

_.union = function(obj, fn, context) {
  if (context == null) { context = this; }
  if (fn) { obj = _.map(obj, fn, context); }
  return _.unique(_.flatten(obj));
};

_.count = function(obj, fn, context) {
  if (context == null) { context = this; }
  let count = 0;
  _.each(obj, function(value, key, obj) { if (fn.call(context, value, key, obj)) { return count++; } });
  return count;
};

_.extend = function(obj, oldObj, newObj) {
  if (oldObj) { _.each(oldObj, (value, key) => obj[key] = value); }
  if (newObj) { _.each(newObj, (value, key) => obj[key] = value); }
  return obj;
};

_.addPathNameKey = function(obj) {
  return _.extend(obj, {'pathname': decodeURIComponent(window.location.pathname)})
}

_.clone = function(obj) {
  if (!_.isObject(obj)) { return obj; }
  return _.reduce(obj, function(result, value, key) {
    result[key] = _.clone(value);
    return result;
  }
  , {});
};

_.compact = array => _.filter(array, item => item);

_.compactObject = function(obj) {
  if (obj == null) { obj = {}; }
  return _.reduce(obj, function(result, value, key) {
    if (value != null) {
      if (_.isObject(value)) {
        value = _.compactObject(value);
        if (!_.isEmptyObject(value)) { result[key] = value; }
      } else {
        result[key] =  value;
      }
    }
    return result;
  }
  , {});
};

_.isString = value => typeof value === 'string';

_.isFunction = value => typeof value === 'function';

_.isObject = value => (value !== null) && (typeof value === 'object');

_.isDefined = value => (value !== null) && (value !== undefined);

_.isEmptyString = value => value.length === 0;

_.isUsefulString = value => _.isDefined(value) && !_.isEmptyString(value);

_.isEmptyObject = value => Object.keys(value).length === 0;

_.isEqual = function(obj1, obj2) {
  if (typeof obj1 !== typeof obj2) { return false; }
  if (!_.isDefined(obj1) || !_.isDefined(obj2)) { return obj1 === obj2; }

  switch (typeof obj1) {
    case 'object':
      return _.isEqualObject(obj1, obj2);
    case 'array':
      return !_.any(obj1, (value, index) => !_.isEqual(value, obj2[index]));
    default:
      return obj1 === obj2;
  }
};

_.isEqualObject = function(obj1, obj2) {
  const keys1 = _.filter(_.keys(obj1), key => obj1[key] !== undefined);
  const keys2 = _.filter(_.keys(obj2), key => obj2[key] !== undefined);
  if (keys1.length !== keys2.length) { return false; }
  return !_.any(keys1, key => !_.isEqual(obj1[key], obj2[key]));
};


_.isZeroCSSValue = value => (value === '0') || (value === '0px') || (value === '0em') || (value === '0%');

//Helper methods

(function() {
  let localDB;
  try {
    localStorage.setItem('testLocalDB', true);
    localDB = (localStorage.getItem('testLocalDB') != null);
    localStorage.removeItem('testLocalDB');
  } catch (error) {
    localDB = false;
  }

  return _.canUseLocalDB  = () => localDB;
})();

_.isIframe = () => parent !== window;

_.extractTempData = (event, widget, defaultData) => {
  let tempItems = defaultData;
  if(event && event.type === 'error') {
    return tempItems;
  }
  tempItems = widget.get(rh.consts('KEY_TEMP_DATA'));
  widget.publish(rh.consts('KEY_TEMP_DATA'));
  return tempItems;
};

_.loadScript = (jsPath, async = true, onload = null, autodelete = false, onerror = null) =>{
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = async === true;
  script.src = jsPath;
  script.onload = _.createFunc(onload, autodelete, script);
  script.onerror = (onerror && _.createFunc(onerror, autodelete, script)) || script.onload;
  return document.body.appendChild(script);
};

_.createFunc = (func, autodelete, script) => {
  return function(args) {
    if (autodelete) { document.body.removeChild(script); }
    return func && func.call(null, args);
  }
};

(function() {
  const randomStr = () => Math.floor((1 + Math.random()) * 0x10000).toString(32).substring(1);

  return _.uniqueId = () => `${_.time().toString(32)}_${randomStr()}${randomStr()}${randomStr()}`;
})();

_.one = fn =>
  function() {
    if ('function' === typeof fn) {
      const fn1 = fn;
      fn = null;
      return fn1.apply(this, arguments);
    }
  }
;

_.cache = function(isValid, cache) {
  if (cache == null) { cache = {}; }
  return function(name, value) {
    if (arguments.length === 1) {
      return cache[name];
    } else if (!isValid || isValid(value)) {
      return cache[name] = value;
    }
  };
};

_.memoize = function(generator, cache) {
  if (cache == null) { cache = {}; }
  return function() {
    let fullkey;
    for (let arg of Array.from(arguments)) {
      const key = _.isString(arg) ? arg : JSON.stringify(arg);
      fullkey = (fullkey != null) ? `${fullkey}, ${key}` : key;
    }

    if (fullkey in cache) {
      return cache[fullkey];
    } else {
      return cache[fullkey] = generator.apply(this, arguments);
    }
  };
};

// last argument of generator function should be callback function
_.memoizeAsync = function(generator, cache) {
  if (cache == null) { cache = {}; }
  return function() {
    let callback;
    const args = [];
    for (let arg of Array.from(arguments)) { args.push(arg); }
    if (args.length > 1) { callback = (args.pop)(); }
    const fullkey = args.join(', ');
    if (fullkey in cache) {
      return (typeof callback === 'function' ? callback(cache[fullkey]) : undefined);
    } else {
      args.push(function(data) {
        cache[fullkey] = data;
        return (typeof callback === 'function' ? callback(data) : undefined);
      });
      return generator.apply(this, args);
    }
  };
};

_.require = _.memoizeAsync((jsPath, callback) => _.loadScript(jsPath, true, () => callback(_.exports())));

(function() {
  let cache = undefined;
  return _.exports = function(value) {
    const retValue = cache;
    cache = (value != null) ? value : undefined;
    return retValue;
  };
})();
