let { rh } = window;
let { _ } = rh;

_.addEventListener(window, 'message', function(e) {
  let config, key;
  if (!_.isSameOrigin(e.origin)) { return; }

  let { data } = e;
  if (!_.isObject(data)) { return; }

  if (data.rhmodel_publish) {
    config = data.rhmodel_publish;
    if (config) { for (key in config) { let value = config[key]; rh.iframe.publish(key, value, {sync: true}); } }
  }
  
  if (data.rhmodel_subscribe) {
    config = data.rhmodel_subscribe;
    let input = config.input || [];
    let topContainer = !rh.model.get('_sharedkeys.input');
    let keys = _.reduce(input, function(result, item) {
      if (topContainer || (item.nested !== false)) { result.push(item.key); }
      return result;
    }
    , []);
    if (keys != null ? keys.length : undefined) { rh.iframe.linkModel(e.source, config.id, keys); }
  }
  
  if (data.rhmodel_unsubscribe) {
    config = data.rhmodel_unsubscribe;
    return rh.iframe.clean(config.id);
  }
});
