let { rh } = window;
let { _ } = rh;

rh._params = _.urlParams();
rh._debugFilter = _.toRegExp(rh._params.rh_debug);
rh._debug = false;

rh._testFilter = _.toRegExp(rh._params.rh_test);
rh._test = false;

rh._errorFilter = _.toRegExp(rh._params.rh_error);
rh._error = false;

rh._breakFilter = _.toRegExp(rh._params.rh_break);
rh._break = false;

let matchFilter = (messages, filter) => messages.join(' ').match(filter);

rh._d = function() {
  let { console } = window;
  if (rh._debug && console && _.isFunction(console.log)) {
    let fn;
    let args = []; let i = -1;
    while (++i < arguments.length) { args.push(arguments[i]); }
    if (['info', 'log', 'warn', 'debug', 'error'].indexOf(args[0]) > -1) {
      fn = console[args[0]];
      args = args.slice(1);
    } else {
      fn = console.debug;
    }

    let newArgs = [`[ ${args[0]} ]:`].concat(args.slice(1));
    if ((rh._debugFilter === '') || matchFilter(newArgs, rh._debugFilter)) {
      if (rh._error && matchFilter(newArgs, rh._errorFilter)) {
        return console.error.apply(console, newArgs);
      } else {
        return fn.apply(console, newArgs);
      }
    }
  }
};
