let { rh } = window;
let { _ } = rh;
let { consts } = rh;

// ChildNode private class for Model
class ChildNode {

  constructor(subscribers, children) {
    if (subscribers == null) { subscribers = []; }
    this.subscribers = subscribers;
    if (children == null) { children = {}; }
    this.children = children;
  }

  // TODO: add key.* support in get
  getSubscribers(keys, path, value, subs) {
    if (keys.length > 1) {
      let child;
      subs.push({fnsInfo: this.subscribers, key: path, value});
      let childKey = keys[1];
      if (child = this.children[childKey]) {
        let newPath = `${path}.${childKey}`;
        child.getSubscribers(keys.slice(1), newPath, value != null ? value[childKey] : undefined, subs);
      }
    } else if (keys.length > 0) {
      this._getAllChildSubscribers(path, value, subs);
    }
    return subs;
  }

  addSubscribers(fn, keys, opts) {
    if (keys.length === 1) {
      return this.subscribers.push([fn, opts]);
    } else if (keys.length > 1) {
      let childKey = keys[1];
      if (this.children[childKey] == null) { this.children[childKey] = new ChildNode(); }
      return this.children[childKey].addSubscribers(fn, keys.slice(1), opts);
    }
  }

  removeSubscriber(fn, keys) {
    if (keys.length === 1) {
      return this._deleteSubscriber(fn);
    } else if (keys.length > 1) {
      return this.children[keys[1]].removeSubscriber(fn, keys.slice(1));
    }
  }

  _deleteSubscriber(fn) {
    let index = _.findIndex(this.subscribers, item => item[0] === fn);
    if ((index != null) && (index !== -1)) {
      return this.subscribers.splice(index, 1);
    } else if (rh._debug) {
      return rh._d('error', '_unsubscribe',
        `${this}.{key} is not subscribed with ${fn}`);
    }
  }

  _getAllChildSubscribers(path, value, subs) {
    subs.push({fnsInfo: this.subscribers, key: path, value});
    if (this.children) {
      if (value == null) { value = {}; }
      for (let key in this.children) {
        let child = this.children[key];
        child._getAllChildSubscribers(`${path}.${key}`, value[key], subs);
      }
    }
    return subs;
  }
}

//RootNode prive class for Model
class RootNode extends ChildNode {

  constructor(subscribers, children, data) {
    super()
    this.subscribers = subscribers;
    this.children = children;
    if (data == null) { data = {}; }
    this.data = data;
    super(this.subscribers, this.childs);
  }

  getSubscribers(keys) {
    let childKey = keys[0];
    let child = this.children[childKey];
    if (child) {
      return child.getSubscribers(keys, `${keys[0]}`, this.data[keys[0]], []);
    } else {
      return [];
    }
  }

  addSubscribers(fn, keys, opts) {
    let childKey = keys[0];
    if (this.children[childKey] == null) { this.children[childKey] = new ChildNode(); }
    return this.children[childKey].addSubscribers(fn, keys, opts);
  }

  removeSubscriber(fn, keys) {
    let childKey = keys[0];
    return (this.children[childKey] != null ? this.children[childKey].removeSubscriber(fn, keys) : undefined);
  }

  getData(keys) {
    let value;
    let { data } = this;
    for (let index = 0; index < keys.length; index++) {
      let key = keys[index];
      if (_.isDefined(data)) {
        if (index === (keys.length - 1)) {
          value = data[key];
        } else {
          data = data[key];
        }
      } else {
        break;
      }
    }
    return value;
  }

  setData(keys, value) { //a.b a.*
    let { data } = this;
    for (let index = 0; index < keys.length; index++) {
      let key = keys[index];
      if (index === (keys.length - 1)) {
        data[key] = value;
      } else {
        if (!_.isDefined(data[key])) { data[key] = {}; }
        data = data[key];
      }
    }
  }
}

// Model class to read write local data using publish subscribe pattern
var Model = (function() {
  let _count = undefined;
  Model = class Model {
    static initClass() {

      // private static variable
      _count = 0;
    }

    toString() { return `Model_${this._count}`; }

    constructor() {
      this._rootNode = new RootNode();

      this._count = _count;
      _count += 1;
    }

    get(key) {
      let value;
      if (this._isForGlobal(key)) { return rh.model.get(key); }

      if (_.isString(key)) {
        value = this._rootNode.getData(this._getKeys(key));
      } else {
        rh._d('error', 'Get', `${this}.${key} is not a string`);
      }

      if (rh._debug) {
        rh._d('log', 'Get', `${this}.${key}: ${JSON.stringify(value)}`);
      }

      return value;
    }

    cget(key) { return this.get(consts(key)); }

    // TODO: add options to detect change then only trigger the event
    publish(key, value, opts) {
      if (opts == null) { opts = {}; }
      if (this._isForGlobal(key)) { return rh.model.publish(key, value, opts); }
      if (rh._debug) {
        rh._d('log', 'Publish', `${this}.${key}: ${JSON.stringify(value)}`);
      }
      if (_.isString(key)) {
        this._rootNode.setData(this._getKeys(key), value);
        let subs = this._rootNode.getSubscribers(this._getKeys(key));
        let keyLength = key[0] === '.' ? key.length - 1 : key.length;
        let filteredSubs = _.map(subs, function(sub) {
          let fnsInfo = _.filter(sub.fnsInfo, fnInfo => _.isDefined(fnInfo[0]) &&
            ((fnInfo[1].partial !== false) || (sub.key.length >= keyLength))
           );
          return {
            key: sub.key,
            value: sub.value,
            fns: _.map(fnsInfo, fnInfo => fnInfo[0])
          };
      });

        _.each(filteredSubs, sub => {
          return _.each(sub.fns, fn => {
            if (rh._debug) {
              rh._d('log', 'Publish call',
                `${this}.${sub.key}: ${JSON.stringify(sub.value)}`);
            }
            let unsub = () => this._unsubscribe(sub.key, fn);
            if (opts.sync) {
              return fn(sub.value, sub.key, unsub);
            } else {
              return rh._.defer(fn, sub.value, sub.key, unsub);
            }
          });
        });
      } else {
        rh._d('error', 'Publish', `${this}.${key} is not a string`);
      }
    }

    cpublish(key, value, opts) {
      return this.publish(consts(key), value, opts);
    }

    isSubscribed(key) {
      let found;
      if (this._isForGlobal(key)) { return rh.model.isSubscribed(key); }
      if (key[0] === '.') { key = key.substring(1); }
      let subs = this._rootNode.getSubscribers(this._getKeys(key));
      for (let sub of Array.from(subs)) { if (sub.key === key) { found = true; } }
      return found === true;
    }

    cisSubscribed(key) { return this.isSubscribed(consts(key)); }

    subscribeOnce(key, fn, opts) {
      if (opts == null) { opts = {}; }
      let keys = _.isString(key) ? [key] : key;
      return this._subscribe(keys.splice(0, 1)[0], (value, key, unsub) => {
        if (keys.length === 0) {
          fn(value, key);
        } else {
          this.subscribeOnce(keys, fn, opts);
        }
        return unsub();
      }
      , opts);
    }

    csubscribeOnce(key, fn, opts) {
      return this.subscribeOnce(consts(key), fn, opts);
    }

    subscribe(key, fn, opts) {
      if (opts == null) { opts = {}; }
      if (_.isString(key)) {
        return this._subscribe(key, fn, opts);
      } else {
        let unsubs = _.map(key, item => this._subscribe(item, fn, opts));
        return () => _.each(unsubs, unsub => unsub());
      }
    }

    csubscribe(key, fn, opts) { return this.subscribe(consts(key), fn, opts); }

    _subscribe(key, fn, opts) {
      if (opts == null) { opts = {}; }
      if (this._isForGlobal(key)) { return rh.model.subscribe(key, fn, opts); }
      if (rh._debug) { rh._d('log', 'Subscribe', `${this}.${key}`); }

      this._rootNode.addSubscribers(fn, this._getKeys(key), opts);
      let value = this._rootNode.getData(this._getKeys(key));
      let unsub = () => this._unsubscribe(key, fn);
      if (opts.forceInit || ((value != null) && !opts.initDone)) { fn(value, key, unsub); }
      return unsub;
    }

    _unsubscribe(key, fn) {
      if (this._isForGlobal(key)) { return rh.model._unsubscribe(key); }
      if (rh._debug) { rh._d('log', '_Unsubscribe', `${this}.${key}`); }
      return this._rootNode.removeSubscriber(fn, this._getKeys(key));
    }

    isGlobal() { return this === rh.model; }

    isGlobalKey(key) { return key && (key[0] === '.'); }

    _isForGlobal(key) { return !this.isGlobal() && this.isGlobalKey(key); }

    _getKeys(fullKey) {
      let keys = fullKey.split('.');
      if (keys[0] === '') { keys = keys.slice(1); } //strip first global key .
      if (rh._debug && (keys.length === 0)) {
        rh._d('error', 'Model', `${this}.${fullKey} is invalid`);
      }
      return keys;
    }
  };
  Model.initClass();
  return Model;
})();

//global object
rh.Model = Model;
rh.model = new Model();
rh.model.toString = () => 'GlobalModel';
