let { rh } = window;
let util = rh._;
let { $ } = rh;

let dataWidget = function(attr) {
  class DataWidget extends rh.Widget {
    static initClass() {
  
      this.prototype.dataAttrMethods = (function() {
        let map = {};
        map[`data-${attr}`] = `data_${attr}`;
        return map;
        })();
    }

    toString() { return `${attr}_${this._count}`; }

    constructor(opts) {
      super(opts);
      
      // Use global model unless someone gives you in javascript
      if (this.model == null) { this.model = rh.model; }
      $.dataset(this.node, attr, opts.rawArg);
    }

    init(parent) {
      if (this.initDone) { return; }
      this.initDone = true;
      this.initParent(parent);
      this.initUI();
      return this.resolveDataAttrs(this.node);
    }
  }
  DataWidget.initClass();
      
  return DataWidget;
};
    
for (let attr of Array.from(rh.Widget.prototype.dataAttrs)) { window.rh.widgets[attr] =  dataWidget(attr); }