//JavaScript handler.
let rh = require("../../src/lib/rh")

rh.IndigoSetSidebar = function() {
	var sideBarToSet = rh.model.get(rh.consts('SIDEBAR_STATE'));

	var body = document.getElementsByTagName("body")[0];
	var toc = document.getElementById("toc-holder");
	var idx = document.getElementById("idx-holder");
	var glo = document.getElementById("glo-holder");
	var fts = document.getElementById("fts-holder");
	var filter = document.getElementById("filter-holder");
	var fav = document.getElementById("favorites-holder");
	var mobileMenu = document.getElementById("mobile-menu-holder");

	var visibleClass = "layout-visible";

	var setVisible = function(elem) {
		if(typeof(elem) != "undefined" && elem != null) {
			elem.classList.add(visibleClass);

			//Keyboard focus on first link element in the popup-visible. This allows better keyboard access.
			var input = elem.getElementsByTagName("input")[0];
			if(typeof(input) != "undefined" && rh.model.get(rh.consts('KEY_SCREEN_DESKTOP'))) {
				if (input.classList.contains("wSearchField")) {
					rh.model.cpublish('EVT_CLOSE_SEARCH_SUGGESTION', true);
					setTimeout(()=>{
						input.focus();
					},300);
				}
				else{
					input.focus();
				}
			} else if (rh.model.get(rh.consts('KEY_SCREEN_DESKTOP'))) {
				var list = elem.getElementsByTagName("li");

				if(typeof(list[0]) != "undefined") {
					list[0].focus();
				} else {

					var links = elem.getElementsByTagName("a");
					if(typeof(links[1]) != "undefined") {
						links[1].focus();
					} else if(typeof(links[0]) != "undefined") {
						links[0].focus();
					}
				}
			}
		}
	}
	var setHidden = function(elem) {
		if(typeof(elem) != "undefined" && elem != null) {
			elem.classList.remove(visibleClass);
		}
	}

	var menuDelay = "has-delay";
	var menuImmediate = "no-transform";

	var showOtherMenu = function() {
		mobileMenu.classList.add(menuDelay);

		setTimeout(function(){
			mobileMenu.classList.remove(menuDelay);
			mobileMenu.classList.add(menuImmediate);
		},750);
	}

	var hideOtherMenu = function() {
		setTimeout(function(){
			mobileMenu.classList.remove(menuImmediate);
		}, 750);
	}

	body.classList.add("popup-visible");

	switch(sideBarToSet) {
		case "toc":
			showOtherMenu();
			setVisible(toc);
			setHidden(idx);
			setHidden(glo);
			setHidden(fts);
			setHidden(filter);
			setHidden(fav);
			setHidden(mobileMenu);
			break;
		case "idx":
			showOtherMenu();
			setVisible(idx);
			setHidden(toc);
			setHidden(glo);
			setHidden(fts);
			setHidden(filter);
			setHidden(fav);
			setHidden(mobileMenu);
			break;
		case "glo":
			showOtherMenu();
			setVisible(glo);
			setHidden(idx);
			setHidden(toc);
			setHidden(fts);
			setHidden(filter);
			setHidden(fav);
			setHidden(mobileMenu);
			break;
		case "fts":
			showOtherMenu();
			setVisible(fts);
			setHidden(idx);
			setHidden(glo);
			setHidden(toc);
			setHidden(filter);
			setHidden(fav);
			setHidden(mobileMenu);
			break;
		case "filter":
			showOtherMenu();
			setVisible(filter);
			setHidden(idx);
			setHidden(glo);
			setHidden(fts);
			setHidden(toc);
			setHidden(fav);
			setHidden(mobileMenu);
			break;
		case "favorites":
			showOtherMenu();
			setVisible(fav);
			setHidden(idx);
			setHidden(glo);
			setHidden(fts);
			setHidden(toc);
			setHidden(filter);
			setHidden(mobileMenu);
			break;
		case "menu":
			setVisible(mobileMenu);
			hideOtherMenu();
			setHidden(idx);
			setHidden(glo);
			setHidden(fts);
			setHidden(toc);
			setHidden(fav);
			setHidden(filter);
			break;
		default: //Nothing. Show topic
			setHidden(idx);
			setHidden(glo);
			setHidden(fts);
			setHidden(toc);
			setHidden(filter);
			setHidden(fav);
			setHidden(mobileMenu);
			hideOtherMenu();
			body.classList.remove("popup-visible");
			if(rh.model.get(rh.consts('KEY_SCREEN_DESKTOP'))) {
				rh.IndigoSetFocusOnSearch();
			}
	}
}
rh.IndigoSetFocusOnSearch = function() {
	var input = document.getElementsByTagName("input");
	for(var i=0;i<input.length;i++){
		if(input[i].classList.contains("wSearchField")){
			rh.model.cpublish('EVT_CLOSE_SEARCH_SUGGESTION', true);
			setTimeout(()=>{
				input[i].focus();
			},300);
			break;
		}
	}
}
rh.IndigoSetSidebarSearch = function() {
	rh.model.publish(rh.consts("SIDEBAR_STATE"), "fts");
}
rh.IndigoSetTransitionAllow = function() {
	var body = document.getElementsByTagName("body")[0];

	var allowPhone = "allow-phone-transitions";
	var allowTablet = "allow-tablet-transitions";
	var allowDesktop = "allow-desktop-transitions";

	body.classList.remove(allowPhone);
	body.classList.remove(allowTablet);
	body.classList.remove(allowDesktop);

	var toSet = false;
	if(rh.model.get(rh.consts('KEY_SCREEN_PHONE')) == true) {
		toSet = allowPhone;
	} else if(rh.model.get(rh.consts('KEY_SCREEN_TABLET')) == true) {
		toSet = allowTablet;
	} else if(rh.model.get(rh.consts('KEY_SCREEN_DESKTOP')) == true) {
		toSet = allowDesktop;
	}

	setTimeout(function(){

		body.classList.remove(allowPhone);//Always make sure there is only 1
		body.classList.remove(allowTablet);
		body.classList.remove(allowDesktop);

		body.classList.add(toSet);

	}, 10);

}

rh.initIndigo = () => {

	rh.model.subscribe(rh.consts("SIDEBAR_STATE"), rh.IndigoSetSidebar);
	rh.model.subscribe(rh.consts("EVT_SEARCH_IN_PROGRESS"), rh.IndigoSetSidebarSearch);
	rh.model.subscribe(rh.consts("KEY_SCREEN"), rh.IndigoSetTransitionAllow);

	//When opening the page, check whether there is a highlight term.
	//If found, add it to the search box
	setTimeout(function(){
		var highlight = getUrlParameter(RHHIGHLIGHTTERM);
		if(highlight != "") {
			var input = document.getElementsByTagName("input");
			for(var i=0;i<input.length;i++){
				if(input[i].classList.contains("wSearchField")){
					input[i].value = highlight;
					break;
				}
			}
    rh.model.publish(rh.consts('KEY_SEARCH_TERM'), highlight);
	  }
  }, 250);

	//For Keyboard accessibility, get the ESC key to close overlays
	document.onkeydown = function(evt) {
		evt = evt || window.event;
		if (evt.keyCode == 27) {
			rh.model.publish(rh.consts('SIDEBAR_STATE'), false);
			rh.IndigoSetFocusOnSearch();//Focus on the search for keyboard accessibility
		}
	};
}
