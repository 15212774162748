let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;


let head = $('head', 0);
let style = document.createElement('style');
style.innerHTML = '.rh-hide:not(.rh-animate){display:none !important;}';
head.insertBefore(style, head.childNodes[0]);

_.addEventListener(document, 'DOMContentLoaded', _.one(function() {
  rh.model.publish(consts('EVT_WIDGET_BEFORELOAD'), true, {sync: true});

  _.loadWidgets(document);

  _.loadContentFilter(document);

  return rh.model.publish(consts('EVT_WIDGET_LOADED'), true, {sync: true});
})
);

_.addEventListener(window, "resize", function (){
  rh.model.publish(consts('EVT_RESIZE'), true)
})

if (_.isIframe()) {
  _.addEventListener(window, 'beforeunload', function() {
    rh.model.publish(consts('EVT_BEFORE_UNLOAD'), true, {sync: true});
    return undefined;
  });

  _.addEventListener(window, 'unload', function(event) {
    rh.model.publish(consts('EVT_UNLOAD'), true, {sync: true});
    return undefined;
  });
}
